/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200&display=swap");

@font-face {
  font-family: "typo-round-bold";
  src: url("../public/fonts/Typo_Round_Bold_Demo.otf") format("opentype");
}

@font-face {
  font-family: "typo-round-bold-italic";
  src: url("../public/fonts/Typo_Round_Bold_Italic_Demo.otf") format("opentype");
}

@font-face {
  font-family: "typo-round-italic";
  src: url("../public/fonts/Typo_Round_Italic_Demo.otf") format("opentype");
}

@font-face {
  font-family: "typo-round-light";
  src: url("../public/fonts/Typo_Round_Light_Demo.otf") format("opentype");
}

@font-face {
  font-family: "typo-round-light-italic";
  src: url("../public/fonts/Typo_Round_Light_Italic_Demo.otf") format("opentype");
}

@font-face {
  font-family: "typo-round-regular";
  src: url("../public/fonts/Typo_Round_Regular_Demo.otf") format("opentype");
}

@font-face {
  font-family: "typo-round-thin";
  src: url("../public/fonts/Typo_Round_Thin_Demo.otf") format("opentype");
}

@font-face {
  font-family: "typo-round-thin-italic";
  src: url("../public/fonts/Typo_Round_Thin_Italic Demo.otf") format("opentype");
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

/*oracle section*/
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.hello {
  top: 100px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9;
  text-align: center;
}

span.anytimeVideoHeading {
  display: inline-block;
  width: auto;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px 28px;
  color: #ffffff;
  font-weight: 700;
  font-size: 20px;
}

.oracleSection {
  height: 250px;
  position: relative;
  background-color: #000;
  z-index: 0;
  transition: all 0.5s;
  top: 0;
}

.oracleSection a {
  text-decoration: none;
}

.overflowOracle {
  overflow: hidden scroll;
  height: 100%;
  position: relative;
  padding-bottom: 10px;
}

/* .clickFirstDivSection {
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 5;
    position: absolute;
    left: 0;
    top: 0;
} */

.oracleSectionHeight {
  position: relative;
  height: 100vh;
  background-color: #000;
  overflow: hidden;
  transition: all 0.4s;
  z-index: 0;
  margin-top: 2px;
}

.autoplay-video .rh5v-DefaultPlayer_component {
  background-color: #ffffff;
  position: relative;
}

.autoplay-video .rh5v-DefaultPlayer_component::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 99.5%;
  z-index: 1;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 64%,
      rgba(0, 0, 0, 0.7) 85%);
  /* background: url(../public/assets/images/bannerImage/sliderImage.png); */
  /* background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 44%, rgba(0,0,0,0.5) 100%); */
}

.firstDiv {
  position: relative;
  width: 100%;
  background-color: #dc6641;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #ffffff;
  height: 250px;
  z-index: 2;
  /* transform: translateX(-50%); */
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: end;
  padding: 50px;
  color: #ffffff;
}

.backBtnSlider {
  width: 45%;
  background: transparent;
  border: none;
  font-size: 24px;
  font-weight: 700;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backText {
  margin-left: 10px;
}

.oracleSecondDivTranslate::before,
.oraclefirstDivTranslate::before,
.firstDiv::before,
.secondDiv::before,
.oracleFirstDiv::before,
.firstContentData::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.7); */
  z-index: -1;
}

.oracleSecondDiv::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.firstDiv::after {
  /* content: ""; */
  position: absolute;
  width: 6px;
  height: 100%;
  background: #ffffff;
  z-index: 4;
  right: 0;
}

.secondSliderContent {
  z-index: 2;
  height: 100%;
  padding: 50px;
  color: #e1e1e1;
  font-weight: 100;
}

.firstDivSliderThirdAnimation .carousel-root {
  height: 100%;
}

/* .secondSliderContent .carousel.carousel-slider {
    height: 100%;
} */
.secondSliderContent .carousel-root .carousel.carousel-slider {
  height: 100%;
  overflow: hidden scroll;
}

.mainNavBar .dropdown button.dropdown-toggle {
  border: none;
  height: 100%;
  background: transparent;
  padding: 5px 15px;
  font-size: 13px;
  font-weight: 500;
  outline: none;
}

.hidden-header-btn {
  visibility: hidden !important;
}

.mainNavBar .dropdown button.dropdown-toggle::after {
  content: "";
  display: none;
}

.mainNavBar .navbarMenu .dropdown ul.dropdown-menu.show {
  border: none;
  margin-left: 14px;
}

.blogList {
  width: 100%;
}

li.newsList a.dropdown-item.active,
li.blogList a.dropdown-item.active {
  background: transparent;
  color: #dc6641;
  font-weight: 700;
  padding: 10px;
}

.navbarMenu .dropdown .dropdown-item:active {
  background: transparent;
}

.navbarMenu .dropdown a {
  text-decoration: none;
  color: #28292b;
  font-size: 13px;
  /* font-weight: 400; */
  font-weight: 500;
}

.mainNavBar .navbarMenu .dropdown ul.dropdown-menu.show {
  min-width: 90px;
  height: 32px;
  display: flex;
  align-items: center;
  background: #F5F5F5;
}

.navbarMenu .dropdown a:hover {
  color: #dc6641;
  background: transparent;
}

/* .navbarMenu .dropdown a.active{
    color: #dc6641;
} */

.mainNavBar .navbarMenu .dropdown ul.dropdown-menu.show::before {
  content: "";
  height: 15px;
  width: 15px;
  position: absolute;
  top: -4px;
  left: 8%;
  transform: rotate(45deg);
  z-index: -1;
  background: #F5F5F5;
}

li.newsList::after {
  content: "";
  background: #bdbdbd;
  width: 100%;
  height: 1px;
  position: absolute;
}

li.newsList {
  position: relative;
}

.mainNavBar nav.navbar.navbar-expand-lg .container-fluid a.navbar-brand {
  -webkit-justify-content: end;
  -webkit-align-items: center;
}

.firstDiv1 {
  z-index: 2;
  width: 32%;
  /* text-align: center; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.firstDiv1 a {
  color: #efefef;
  z-index: 5;
}

.ins-preview-wrapper {
  z-index: 2 !important;
}

.firstDiv1:hover button {
  background: transparent;
  border: 1px solid #dc6641;
  color: #dc6641;
}

.firstDiv1:hover h6 {
  background: transparent;
  border: 1px solid #dc6641;
  color: #dc6641;
}

.forAgents {
  background-color: #ffffff;
  cursor: pointer;
  color: #33485b;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 400;
  width: 200px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  opacity: 1;
  transition: 0.4s;
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  font-family: "typo-round-bold", sans-serif;
}

.forDevelopers {
  color: #dc6641 !important;
}

/* @media (hover: hover) { */
button.forAgents:hover {
  background: transparent;
  border: 1px solid #ffffff;
  color: #33485b;
  transition: 0.4s;
}

/* } */
.secondDiv {
  width: 100%;
  background-color: #33485b;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #ffffff;
  height: 250px;
  z-index: 2;
  /* transform: translateX(100%); */
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: start;
  padding: 50px;
  position: relative;
}

.collapseCard div#collapseExample .card.card-body {
  background-color: transparent;
}

.collapseCard {
  background-color: #002532;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.5);
}

.firstDivSliderFirst {
  background-color: #ffffff;
  width: 100%;
  color: #002532;
  height: 100%;
  z-index: 1;
  transition: background-color 0.4s;
  /* transform: translateX(-50%); */
  transition: height 0.4s;
}

.firstDivSliderThird {
  width: 100%;
  background: url("/public/assets/images/yaman3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #ffffff;
  height: 100vh;
  z-index: 1;
  /* transform: translateX(0%); */
  transition: 1s;
  position: relative;
  text-align: center;
  transition: transform 0.8999s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transform: rotateY(-49deg);
  left: -130%;
  padding: 50px;
}

.firstDivSliderThird::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  /* transform: rotateY(-49deg); */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transform-style: preserve-3d;
  /* left: -130%; */
  padding: 50px;
  background-size: cover;
  background-position: center;
  left: 0;
  top: 0;
}

.firstDivSliderThirdAnimation {
  width: 100%;
  background: url("/public/assets/images/yaman3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #ffffff;
  height: 100vh;
  z-index: 1;
  position: relative;
  transition: transform 0.8999s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transform: rotateY(0deg);
  transform: translate(130%);
  left: -130%;
}

.firstContentData {
  width: 100%;
  background: url("/public/assets/images/01.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #ffffff;
  height: 100vh;
  z-index: 1;
  position: relative;
  transition: transform 0.8999s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transform: rotateY(0deg);
  transform: translate(130%);
  left: -130%;
}

.firstContentData .secondSliderContent .carousel-root {
  height: 100%;
}

.firstDivSliderThirdAnimation::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  /* z-index: -1; */
}

.flipCard {
  width: 100%;
  /* height: 100vh; */
  perspective: 1000px;
}

.secondSliderContent li.slide.selected.previous h2 {
  margin-bottom: 70px;
}

.secondSliderContent ul.slider.animated li.slide.selected h2 {
  margin-bottom: 65px;
  margin-top: 50px;
  font-weight: 700;
}

.agentTabHeading {
  text-align: center;
  font-weight: 700;
  margin: 36px 0;
}

/* .firstDivSlidersecond::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
} */

.firstDivSlidersecond {
  width: 100%;
  background: #002532;
  color: #ffffff;
  height: 100vh;
  z-index: 1;
  /* transform: translateX(0%); */
  position: relative;
  text-align: center;
  transition: transform 0.9s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transform: rotateY(84deg);
  perspective: 500px;
  left: -50%;
  padding: 50px;
}

.secondSliderContent div#v-pills-tabContent div#v-pills-home h2 {
  margin-bottom: 70px;
  margin-top: 50px;
  font-weight: 700;
}

.firstDivSlidersecondAnimation {
  width: 100%;
  background: #002532;
  color: #ffffff;
  height: 100vh;
  z-index: 1;
  position: relative;
  text-align: start;
  transition: transform 0.9s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transform: rotateY(0deg);
  transform: translateX(50%);
  left: -50%;
}

.firstContentDataFirstSlide {
  width: 100%;
  background: #043e55;
  color: #ffffff;
  height: 100vh;
  z-index: 1;
  position: relative;
  text-align: start;
  transition: transform 0.9s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transform: rotateY(0deg);
  transform: translateX(50%);
  left: -50%;
}

/* .firstDivSlidersecondAnimation{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
} */

.firstDivSlider {
  top: 0;
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: 1;
  transition: 0.4s;
  perspective: 500px;
}

.oracleFirstDiv {
  width: 100%;
  background: url("/public/assets/images/skyland1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #ffffff;
  height: 100vh;
  z-index: 2;
  /* transform: translateX(-50%); */
  transition: all 0.4s;
  /* transition: width 1s; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: end;
  padding: 50px;
  position: relative;
}

.firstDivSlidersecondOpacity0 h3,
p,
button {
  /* opacity: 0; */
  transition: 0.4s;
}

.firstDivSlidersecondOpacity1 h3,
p,
button {
  /* opacity: 0.8; */
  /* transition: opacity 1s; */
  color: #262626;
  font-size: 16px;
}


.blogContent p1 {
  color: #dc6641;
  text-decoration-line: none;
}

.oracleSecondDiv {
  width: 100%;
  background: url("/public/assets/images/nishantasi1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #ffffff;
  height: 100vh;
  z-index: 2;
  transition: all 1s;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: start;
  padding: 50px;
  position: relative;
}

.oracleSecondDivTranslate {
  width: 100%;
  background: url("/public/assets/images/02.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #ffffff;
  height: 100vh;
  z-index: 2;
  transform: translateX(100%);
  transition: all 0.8s;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: end;
  padding: 50px;
  position: relative;
}

.oraclefirstDivTranslate {
  width: 100%;
  background: url("/public/assets/images/skyland1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #ffffff;
  height: 100vh;
  z-index: 2;
  /* opacity: 0; */
  transform: translateX(-100%);
  transition: transform 0.8s;
  /* transition: opacity 4s;  
    transition: z-index 4s; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: end;
  padding: 50px;
  position: relative;
}

.oraclefirstDivTranslateZindex {
  width: 100%;
  background: url("/public/assets/images/skyland1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #ffffff;
  height: 100vh;
  opacity: 0;
  transform: translateX(-100%);
  transition: 1s;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: end;
  padding: 50px;
  position: relative;
}

.firstDivFlex {
  display: flex;
  overflow: hidden;
}

/*oracle section*/
.mainHomePage {
  position: relative;
  height: auto;
  /* background-color: #f5f5f5; */
}

.cursor-pointer img {
  width: 23px;
}

.mainNavBar {
  position: fixed;
  top: 0;
  z-index: 4;
  /* z-index: 9999; */
  width: 100%;
  height: 65px;
  /* padding-top: 34px; */
  background-color: #ffffff;
  box-shadow: 1px 5px 20px rgb(0 0 0 / 30%);
  /* padding: 8px 0; */
}

.mainNavBar img {
  height: 36px;
}

.mainNavBar ul.navbar-nav {
  margin: auto;
  background: #ffffff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.navbar-toggler:focus {
  color: #ffffff;
}

.temaSection .tema_Landing_page_main .main_banner_content .validationError p,
.skylandSection .tema_istanbul_main_section .main_banner_content .validationError p,
.alyaSection .tema_istanbul_main_section .main_banner_content .validationError p {
  font-size: 14px;
  color: red;
}

.validationError {
  top: 10;
  align-items: center;
}

.errorMsg {
  color: red;
  margin: 0;
}

.signNavbar {
  margin-right: 3rem;
  margin-bottom: 0;
  padding-left: 0;
  position: relative;
}

@media only screen and (min-width: 1245px) {
  .languageDropDown {
    margin-right: 5.2rem;
  }
}

.signNavbar li,
.signNavbarSecond li {
  list-style: none;
}

.projectDetailSlider {
  /* position: absolute; */
  text-align: initial;
  color: aliceblue;
  top: 47%;
  /* left: 7%; */
  width: 100%;
  z-index: 2;
}

.mainHomePage .mainNavBar nav.navbar.navbar-expand-lg {
  padding: 0 45px;
}

.navbarMenu li.nav-item a.nav-link.active {
  color: #dc6641;
  font-weight: 700 !important;
}

.progressBarContainer h3 {
  font-size: 15px;
  font-weight: 400;
  margin-top: 10px;
  text-align: start;
  color: rgba(255, 255, 255, 0.4);
}

.mainHomePage .mainNavBar nav.navbar.navbar-expand-lg {
  padding: 14px 38px;
}

.progressLine.yellow-text span.progressBar {
  background-color: #ffffff;
}

.mainHomePage .carousel .slide img {
  height: 100vh;
  object-fit: cover;
  object-position: 50% 50%;
}

.whyDevTrustUsImg {
  width: 23%;
  object-fit: cover;
  /* object-position: 50% 50%; */
}

.mainHomePage .carousel .control-dots .dot {
  width: 14%;
  border-radius: 0;
  position: relative;
}

.mainHomePage .carousel .control-dots .dot {
  border-radius: 20px;
  height: 5px;
  box-shadow: none;
}

.projectLable {
  position: absolute;
  bottom: 32px;
  width: 13%;
  text-align: center;
  color: #ffffff;
  font-weight: 600;
  font-size: 19px;
}

.mainHomePage .carousel.carousel-slider .control-arrow {
  display: none;
}

.projectDetailSlider h5 {
  font-size: 28px;
  font-weight: 400;
}

.projectDetailSlider h4,
h1 {
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 400;
}

.projectDetailSlider h1 {
  font-weight: 700;
  /* font-family: 'Times New Roman', Times, serif; */
  font-size: 50px;
}

.navbarMenu {
  background: rgba(255, 255, 255, 0.3);
  padding: 0px 10px;
  border-radius: 20px;
  /* border: 1px solid rgba(255, 255, 255, .6); */
  /* width: 50%; */
  justify-content: space-evenly;
  backdrop-filter: blur(5px);
}

.navbarMenu .nav-item a {
  color: #28292b;
  font-weight: 400;
  text-decoration: none;
  padding: 9px 23px;
  font-size: 14px;
}

ul.navbar-nav.navbarMenu li.nav-item a.nav-link {
  padding: 8px 15px;
  /* font-weight: 400; */
  font-weight: 500;
  font-size: 13px;
}

ul.navbar-nav.navbarMenu li.nav-item a.nav-link:hover {
  color: #dc6641;
}

.projectDetailSlider svg {
  margin-right: 6px;
}

.projectBtn {
  background-color: #dc6641;
  border: none;
  border-radius: 5px;
  /* padding: 12px 69px; */
  font-size: 12px;
  color: #ffffff;
  /* font-weight: 600; */
  cursor: pointer;
  /* margin-top: 30px; */
  margin-top: 34px;
  width: 15%;
  height: 41px;
}

.projectBtnExplore {
  background-color: #dc6641;
  border: none;
  border-radius: 4px;
  padding: 6px 34px;
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
  cursor: pointer;
  margin-top: 70px;
}

.secondSliderContent .carousel .control-dots {
  text-align: start;
  bottom: -14px;
}

.secondSliderContent p.carousel-status {
  display: none;
}

.projectBtn:focus {
  outline: none;
}

/* .signBtn {
    background-color: #dc6641;
    color: #ffffff;
    border-radius: 20px;
    padding: 6px 20px;
} */

.agentDeveloperSection {
  /* padding: 20px 0; */
  margin-top: -6px;
  height: 300px;
}

.agentDeveloperSectionTrue {
  height: 100vh;
  transition: all 0.4s ease-in-out;
}

.exploreMoreBtn {
  background-color: #dc6641;
  border: none;
  border-radius: 20px;
  color: #ffffff;
  padding: 3px 15px;
}

.dummyContent {
  height: 100vh;
  background-color: #ffffff;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.agentSection {
  background-color: #002532;
  padding: 20px 55px;
  color: #ffffff;
  height: 300px;
  transition: height 2s;
  transition: width 2s;
  text-align: end;
  transform: translateX(-50%);
  transition: transform 0.55s ease-out;
}

.developerSection {
  background-color: #507583;
  padding: 20px 55px;
  color: #ffffff;
  height: 300px;
  width: 100%;
  transition: height 2s;
  transition: width 2s;
  transform: translateX(50%);
  transition: transform 0.55s ease-out;
}

.firstDiv1 h3,
.firstDiv1 p {
  opacity: 1;
  transition: 0.4s;
}

.firstDiv0 h3,
.firstDiv0 p {
  opacity: 0;
  transition: 0.34s;
}

.agentFirstDiv {
  width: 100%;
  transition: width 2s;
}

.agentFirstDivChange {
  width: 50%;
  transition: width 2s;
}

.agentDevelopersSection {
  position: relative;
  transition: width 2s;
  margin-top: -6px;
  height: 300px;
  transition: height 2s;
}

.videoPlayer {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.autoplay-video .rh5v-DefaultPlayer_component {
  background-color: #ffffff;
  position: relative;
}

.autoplay-video .rh5v-DefaultPlayer_component .rh5v-Overlay_component .rh5v-Overlay_icon,
.rh5v-Overlay_inner {
  display: none;
}

.progressStage {
  display: flex;
  align-items: center;
}

.progressStage img {
  margin-right: 5px;
}

.autoplay-video {
  position: relative;
  width: 100%;
  height: 100%;
}

/* .autoplay-video::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 99.3%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
} */

button.backBtnSlider img {
  width: 10px;
  margin-top: 4px;
  margin-right: 5px;
}

.agentSectionheight {
  height: 100vh;
  background-color: #002532;
  padding: 20px;
  color: #ffffff;
  width: 100%;
  transition: height 2s;
  /* transition: width 1s; */
}

.developerSectionheight {
  background-color: #507583;
  height: 100vh;
  width: 100%;
  padding: 20px;
  color: #ffffff;
  transition: height 2s;
  /* transition: width 2s; */
}

.agentSectionWidth {
  /* width: 50%; */
  height: 100vh;
  background-color: #002532;
  padding: 20px;
  color: #ffffff;
  /* width: 50%; */
  /* transition: width 2s; */
  /* transition: height 2s; */
}

.agentDeveloperSectionagent {
  overflow: hidden;
  background-color: #000;
}

.show-transition {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.agentSection h3,
.developerSection h3 {
  font-size: 35px;
  font-weight: 700;
}

.mainHomePage .videoSlider video {
  object-fit: cover;
}

.sliderContainerClass {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
}

.agentMainSection {
  height: 100vh;
  margin-top: -6px;
}

.mainHomePage .autoplay-video .rh5v-Overlay_component {
  display: none;
}

/* .agentMainSection,
.developerMainSection {
    height: auto;
    transition: all ease-in-out 5s;
    margin-top: -7px;
} */
.animate__animated.animate__flipInY {
  animation-duration: 1s;
  height: 100vh;
}

/* h3 {
    margin: 5px 0;
} */

.sliderProgressBarClass .container {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 20%;
}

.sliderContainer {
  position: relative;
}

.slick-prev:before,
.slick-next:before {
  color: black;
}

.slick-dots {
  bottom: -30px;
}

.yellow-text h3 {
  color: #ffffff;
  font-weight: 600;
}

.progressBarContainer {
  position: absolute;
  /* bottom: 68px; */
  width: 100%;
  text-align: center;
  /* left: 150px; */
  display: flex;
  justify-content: space-between;
}

.progressBarContainer .progressLine {
  display: block;
  width: 30%;
  padding: 0;
  cursor: pointer;
  margin-right: 5%;
  float: left;
  color: rgba(255, 255, 255, 0.7);
}

.progressBarContainer div:last-child {
  margin-right: 0;
}

.formFooterContent p {
  font-size: 10px !important;
}

.progressBarContainer div span.progressBar {
  height: 3px;
  background-color: rgba(255, 255, 255, 0.4);
  display: block;
  border-radius: 20px;
}

.progressBarContainer div span.progressBar .inProgress {
  background-color: #dc6641;
  width: 0%;
  height: 3px;
  border-radius: 20px;
}

.agentTabs {
  background-color: #002532;
  color: #ffffff;
  padding: 40px;
  height: 100vh;
  position: relative;
}

.DevelopersTabs {
  background-color: #002532;
  color: #ffffff;
  padding: 40px;
  height: 100vh;
  position: relative;
}

.developersSection {
  background-color: #202020;
  color: #ffffff;
  padding: 40px;
  height: auto;
  position: relative;
}

.mainHomePage .nav-pills .nav-link.active {
  /* color: red; */
  background-color: #002532;
  border-radius: 0;
  padding: 12px;
  color: #ffffff;
}

.mainHomePage .nav-pills .nav-link {
  font-size: 20px;
  font-weight: 700;
  color: #002532;
}

.firstSection {
  margin-top: 20px;
}

.firstSection .backBtnofagent {
  color: #002532;
  background: none;
  border: none;
  font-size: 29px;
  font-weight: 700;
}

.mainNavBar nav.navbar.navbar-expand-lg button.navbar-toggler:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.autoplay-video .rh5v-DefaultPlayer_controls {
  display: none;
}

.mainNavBar a.navbar-brand {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 68%; */
  margin-right: 0;
}

/*media Quries*/

@media (max-width: 1368px) {
  /* .signNavbarSecond {
        display: none;
    } */
}

@media (max-width: 1366px) and (orientation: landscape) {
  .autoplay-video .container {
    /* bottom: 16%; */
  }
}

@media (max-width: 1366px) {
  .progressBarContainer h3 {
    font-size: 13px;
  }

  .progressBarContainer {
    /* bottom: 71px; */
  }

  .autoplay-video .container {
    /* bottom: 33%; */
  }

  .projectDetailSlider h1 {
    font-size: 40px;
  }

  /* .signNavbarSecond {
        display: none;
    } */

  .wrapper {
    height: 76vh;
  }

  .tema2dContent {
    top: 29%;
  }

  .temaContentBoxTrue h3 {
    font-size: 62px;
  }

  .temaContentBoxFalse h3 {
    font-size: 62px;
  }
}

/* @media (max-width: 1366px) {
    .progressBarContainer {
        bottom: 106px;
    }
} */

/* @media (max-width:1280) {
    .progressBarContainer{
        bottom: 200px;
    }
    .autoplay-video .container{
        top: 71%;
    }
} */

.autoplay-video .container {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 22%;
}

/* @media (max-width:1300px) {
    .progressBarContainer{
        bottom: 150px;
    }
    .autoplay-video .container{
        bottom: 39%;
    }
} */

/* @media (max-width:1300px) {
    .autoplay-video .container {
        bottom: 32%;
    }

    .newPortalContactSection .ourOfficeSection {
        margin-top: 50%;
    }

    .newPortalContactSection .contactFormContent h3 {
        font-size: 35px;
    }

    .newPortalContactSection .contactContentSection .contactFormHeadQurter h3 {
        font-size: 35px;
    }

    .newPortalAboutUsPage .managementSection .managementImages img {
        height: 650px;
    }

    .progressBarContainer {
        bottom: 80px;
    }
} */
@media only screen and (max-width: 1280px) {
  /* .autoplay-video .container {
        bottom: 30%;
    } */

  .progressBarContainer {
    /* bottom: 65px; */
  }
}

@media (max-width: 1024px) {
  .mainHomePage .autoplay-video .rh5v-DefaultPlayer_component.videoPlayer .rh5v-DefaultPlayer_video {
    height: 100%;
    /* object-fit: unset; */
  }

  .projectDetailSlider {
    /* margin-left: 35px; */
    top: 196%;
  }

  .wrapper {
    height: 38vh;
  }

  .tema2dContent {
    top: 24%;
    width: 39%;
  }

  /* .overflowOracle {
        overflow: visible;
    }

    .secondSliderContent .carousel-root .carousel.carousel-slider {
        overflow: visible;
    } */

  /* .navbarMenu {
        width: 78%;
    } */

  .firstDiv1 {
    width: 52%;
  }

  .projectDetailSlider h1 {
    font-size: 30px;
  }

  button.seeAllProjectButton .progressBarContainer {
    bottom: 20px;
  }

  .progressBarContainer {
    /* bottom: 50px; */
  }

  /* .autoplay-video .container {
        top: unset !important;
        bottom: 30%;
    } */
}

@media (max-width: 1024px) and (orientation: landscape) {
  .wrapper {
    height: 75vh;
  }
}

@media (max-width: 991px) {
  .mainNavBar .navbarMenu .dropdown ul.dropdown-menu.show::before {
    content: unset;
  }
}

@media (max-width: 812px) {
  .projectBtn {
    margin-top: 5px;
  }

  .firstDiv1 {
    width: 72%;
  }

  .oracleSectionHeight {
    height: auto;
    margin-bottom: 67%;
  }

  .overflowOracle {
    padding-top: 20px;
  }

  .firstDivSlidersecondAnimation {
    min-height: 550px;
    overflow-y: scroll;
  }

  .projectBtn {
    margin-bottom: 20px;
  }

  .secondSliderContent div#v-pills-tabContent div#v-pills-home h2 {
    margin-bottom: 29px;
    margin-top: -24px;
    font-weight: 700;
  }

  .secondSliderContent ul.slider.animated li.slide.selected h2 {
    margin-bottom: 20px;
    margin-top: 5px;
  }

  .projectBtnExplore {
    margin-top: 20px;
  }

  /* .firstDivSliderFirst {
        min-height: 550px;
    } */

  .firstDivSliderThirdAnimation {
    min-height: 550px;
    overflow-y: scroll;
  }

  .oracleSectionHeight {
    overflow-y: visible;
    overflow-x: visible;
  }

  /* .signNavbar {
        display: none;
    } */

  .navbarMenu .nav-item a {
    color: #000;
  }

  .navbarMenu {
    background-color: #ffffff;
  }

  .navbarMenu {
    width: 100%;
  }

  .navbar-collapse {
    /* background-color: #ffffff; */
    width: 10%;
    /* height: 191px; */
    margin-top: 5px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  /* .signNavbarSecond {
        display: block;
        margin: 0;
    } */

  /* .mainNavBar nav.navbar.navbar-expand-lg .container-fluid a.navbar-brand {
        width: 85%;
        justify-content: end;
        align-items: center;
    } */

  .projectDetailSlider h4,
  h1,
  h5 {
    margin-bottom: 8px;
  }

  .projectDetailSlider {
    /* margin-left: -2px; */
    top: 40%;
  }
}

@media only screen and (min-device-width: 1025px) and (max-device-width: 1180px) {
  ul.navbar-nav.navbarMenu li.nav-item a.nav-link {
    padding: 8px 13px;
    font-weight: 500;
    font-size: 13px;
  }

  .container {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: 10px;
    margin-left: 50px;
  }

  .signNavbar {
    margin-right: 1rem;
    margin-bottom: 0;
    padding-left: 0;
    position: relative;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1100px) {
  ul.navbar-nav.navbarMenu li.nav-item a.nav-link {
    padding: 8px 11px;
    font-weight: 500;
    font-size: 13px;
  }

  /* .container {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        width: 100%;
        padding-right: calc(var(--bs-gutter-x) * .5);
        padding-left: calc(var(--bs-gutter-x) * .5);
        margin-right: 10px;
        margin-left: 20px;
    } */
  .signNavbar {
    margin-right: 4rem;
    margin-bottom: 0;
    padding-left: 0;
    position: relative;
    right: 22px;
  }

  #langbar {
    margin-right: 3rem;
    margin-bottom: 0;
    padding-left: 0;
    position: relative;
    right: 64px !important;
  }

  .select-box {
    width: 100px !important;
  }

}

@media only screen and (min-device-width: 280px) {
  .login-icon {
    margin-right: -32px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1000px) {
  #nav-btn {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  .signNavbar {
    margin-right: 3rem;
    margin-bottom: 0;
    padding-left: 0;
    position: relative;
    right: 22px;
  }

  .lang-logo {
    width: 74px;
    object-fit: cover;
    margin-right: -20px;
    margin-bottom: 1px;
  }

  #nav-lang-list {
    width: 50%;
    background-color: white;
    float: right;
    line-height: 40px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin: 16px;
    padding-left: 0px !important;
  }

  .lang-option {
    list-style-type: none;
    text-align: center;
  }

  .signin-logo {
    margin-right: -30px;
  }

}

@media (max-width: 896px) and (orientation: landscape) {
  .wrapper {
    height: 100vh;
  }

  .temaContentBoxTrue h3 {
    font-size: 40px;
  }

  .temaContentBoxFalse h3 {
    font-size: 40px;
  }

  .tema2dButtonTrue {
    margin-top: 20px;
  }

  .tema2dButtonFalse {
    margin-top: 20px;
  }

  #nav-btn {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  .lang-logo {
    width: 74px;
    object-fit: cover;
    margin-right: -20px;
    margin-bottom: 1px;
  }

  #nav-lang-list {
    width: 50%;
    background-color: white;
    float: right;
    line-height: 40px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin: 15px;
    padding-left: 0px !important;
  }

  .lang-option {
    list-style-type: none;
    text-align: center;
  }

  .lang-option.active {
    color: #dc6641;
    font-weight: 700 !important;
  }

  .signin-logo {
    margin-right: -30px;
  }
}

.lang-option.active {
  color: #dc6641;
}

@media only screen and (min-device-width: 667px) and (max-device-width: 740px) {
  .navbar-brand img {
    margin-left: 112px;
  }

  .signNavbar {
    margin-right: -1rem;
    margin-bottom: 0;
    padding-left: 0;
    position: relative;
  }

}

@media (max-width: 812px) and (orientation: landscape) {
  .wrapper {
    height: 100vh;
  }

  .oracleSectionHeight {
    margin-bottom: 140%;
  }

  .tema2dButtonTrue button {
    font-size: 11px;
  }

  .temaContentBoxTrue h3 {
    font-size: 30px;
  }

  .temaContentBoxFalse h3 {
    font-size: 35px;
  }

  .oneMoreMix {
    top: 44%;
  }

  .mainNavBar a.navbar-brand {
    /* width: 85%; */
    justify-content: flex-end;
    align-items: center;
    margin-right: 0;
  }

  /* .autoplay-video .container {
        top: unset !important;
        bottom: 36% !important;
    } */
}

@media (min-width: 280px) and (max-width: 374px) {

  #nav-btn {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  .lang-logo {
    width: 74px;
    object-fit: cover;
    margin-right: -20px;
    margin-bottom: 1px;
  }

  #nav-lang-list {
    width: 50%;
    background-color: white;
    float: right;
    line-height: 40px;
    border-radius: 10%;
    margin: 10px;
    padding-left: 0px !important;
  }

  .lang-option {
    list-style-type: none;
    text-align: center;
  }

  .navbar-brand img {
    margin-left: 9px;
    margin-right: -11px;
  }

  .signin-logo {
    margin-left: -4px;
    margin-right: -33px;
  }

}

@media (min-width: 375px) and (max-width: 664px) {

  .autoplay-video .container {
    bottom: 27%;
  }

  .sliderProgressBarClass .container {
    bottom: 24%;
  }

  .progressBarContainer h3 {
    font-size: 11px;
  }

  .mainNavBar .navbar .navbar-toggler-icon {
    background-image: url("../public/assets/images/list.svg");
    background-repeat: no-repeat;
  }

  .autoplay-video .container {
    /* bottom: 32%; */
  }

  .projectDetailSlider h1 {
    font-size: 25px;
    margin-bottom: 10px;
  }

  .progressBarContainer {
    /* bottom: 50px; */
  }

  /* .wrapper{
        margin-top: 67%;
    } */
  .temaContentBoxTrue h3 {
    font-size: 32px;
  }

  .temaContentBoxFalse h3 {
    font-size: 62px;
  }

  .tema2dButtonTrue button {
    font-size: 11px;
  }

  .tema2dContentDescriptionTrue p {
    font-size: 12px;
  }

  .tema2dContentDescriptionFalse p {
    font-size: 12px;
  }

  .tema2dContent {
    top: 24%;
  }

  .navbarMenu {
    width: 100%;
  }

  /* .oracleSectionHeight {
        overflow-y: hidden;
        overflow-x: hidden;
    } */
  #nav-btn {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  .lang-logo {
    width: 74px;
    object-fit: cover;
    margin-right: -20px;
    margin-bottom: 1px;
  }

  #nav-lang-list {
    width: 50%;
    background-color: white;
    float: right;
    line-height: 40px;
    border-radius: 10%;
    margin: 10px;
    padding-left: 0px !important;
  }

  .lang-option {
    list-style-type: none;
    text-align: center;
  }

  .signin-logo {
    margin-right: -30px;
  }

  .navbar-collapse {
    /* height: 191px; */
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-top: 5px;
  }

  /* .signNavbar {
        display: none;
    } */

  .signNavbar {
    /* padding-left: 47px; */
    /* width: 35%; */
  }

  .projectDetailSlider h4,
  h5 {
    margin-bottom: 10px;
  }

  .projectBtn {
    margin-top: 10px;
  }

  .projectDetailSlider {
    /* margin-left: 26px; */
  }

  .mainNavBar a.navbar-brand {
    /* width: 85%; */
    justify-content: flex-end;
  }

  ul.signNavbar svg.bi.bi-person-fill {
    margin-top: -8px;
  }

  .firstDivSlider,
  .firstDivSliderThirdAnimation,
  .firstDivSlidersecondAnimation {
    height: auto;
  }

  .secondSliderContent .carousel-root .carousel.carousel-slider {
    height: 100%;
    overflow: visible, scroll;
  }

  .secondSliderContent .carousel .control-dots {
    bottom: -49px;
  }
}

@media (max-width: 767px) {
  .NewwhyDeveloperCarouselSection .photoSection img {
    width: 100px;
  }

  /* 
    .NewwhyDeveloperCarouselSection .tabSectionDevelopers {
        top: 18%;
    } */

  .NewwhyDeveloperCarouselSection .testimonialCarousel1::before,
  .NewwhyDeveloperCarouselSection .testimonialCarousel2::before,
  .NewwhyDeveloperCarouselSection .testimonialCarousel3::before {
    width: 30%;
    top: -81px;
  }

  .newPortalWhoWeAreSection {
    margin-top: 32%;
  }

  .firstDiv::after {
    width: 100%;
    height: 6px;
    bottom: 0;
  }
}

@media (max-width: 736px) and (orientation: landscape) {
  .progressBarContainer h3 {
    font-size: 10px;
  }

  .projectDetailSlider {
    /* margin-left: -56px; */
  }

  .overflowOracle {
    padding-top: 20px;
  }

  .progressBarContainer {
    /* bottom: 38px; */
  }

  .oracleFirstDiv {
    opacity: 0;
  }

  .oracleFirstDiv .oraclefirstDivTranslate {
    opacity: 0;
    transition: 0;
  }

  .firstDivFlex {
    display: block;
  }

  .oracleSectionHeight {
    /* overflow-y: visible; */
    /* overflow-x: visible; */
  }

  .secondSliderContent .carousel.carousel-slider {
    height: 100%;
  }

  .secondSliderContent .carousel-root {
    height: 100%;
  }

  .firstDiv::after {
    /* content: ""; */
    position: absolute;
    width: 0;
    height: 100%;
    background: #ffffff;
    z-index: 4;
    right: 0;
  }

  .secondDiv::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 5px;
    background: white;
    top: 0;
    z-index: 4;
  }

  .mainNavBar a.navbar-brand {
    /* width: 85%; */
    justify-content: flex-end;
    align-items: center;
  }

  .signNavbarSecond {
    margin-bottom: 0;
  }
}

@media (max-width: 736px) and (orientation: landscape) {
  .oracleSection {
    height: auto;
  }

  .tema2dButtonTrue button {
    font-size: 10px;
  }
}

@media (max-width: 728px) {
  .newPortalWhoWeAreSection {
    margin-top: 0;
  }
}

@media (max-width: 686px) {
  .newPortalWhoWeAreSection {
    margin-top: 40%;
  }
}

@media (max-width: 703px) {
  /* .autoplay-video .container {
        top: unset;
        bottom: 30%;
    } */

  /* .autoplay-video .container {
        bottom: 31% !important;
    } */
}

@media (max-width: 650px) {
  .sliderProgressBarClass .container {
    bottom: 18%;
  }
}

@media (max-width: 500px) {
  span.anytimeVideoHeading {
    font-size: 12px;
  }

  .autoplay-video .rh5v-DefaultPlayer_component::before {
    background: linear-gradient(180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 47%,
        rgba(0, 0, 0, 0.7) 85%);
  }

  .autoplay-video .rh5v-DefaultPlayer_component::before {
    background: linear-gradient(180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 47%,
        rgba(0, 0, 0, 0.7) 85%);
  }

  .eventImage .rh5v-DefaultPlayer_video {
    height: auto;
  }

  .eventImage .rh5v-DefaultPlayer_component {
    height: auto;
  }

  .sliderProgressBarClass .container {
    bottom: 10%;
  }

  .newPortalWhoWeAreSection {
    margin-top: 0;
  }

  .forAgents {
    /* width: 76%; */
    min-width: 76%;
    margin: auto;
  }

  .sliderContainer {
    height: 286px;
    margin-bottom: 50px;
  }

  .progressBarContainer {
    /* bottom: 50px; */
    padding: 0 30px;
  }

  .firstDiv::after {
    /* content: ""; */
    position: absolute;
    width: 100%;
    height: 6px;
    background: #ffffff;
    z-index: 4;
    right: 0;
    bottom: 0;
  }

  .mainHomePage .carousel .slide img {
    height: 500px;
    object-fit: cover;
    object-position: 50% 50%;
  }

  .firstDivSliderThirdAnimation {
    height: 89vh;
  }

  .oneMoreMix {
    top: 31%;
  }

  .rh5v-DefaultPlayer_component {
    height: 300px;
  }

  .button-containerTrue {
    bottom: -10px;
    right: 50%;
  }

  .button-containerFalse {
    bottom: -10px;
    right: 40%;
  }

  .button-containerTrue .button {
    margin: 4px;
  }

  .oracleSection {
    height: auto;
    width: 94%;
    margin: auto;
  }

  .wrapper {
    height: 38vh;
    width: 90%;
    margin: auto;
    padding-top: 10px;
  }

  .nutureMainSection video {
    object-fit: cover;
  }

  .tema2dContent {
    top: 12%;
  }

  .tema2dContentDescriptionTrue p {
    font-size: 9px;
    margin-bottom: 0;
  }

  .tema2dContentDescriptionFalse p {
    font-size: 9px;
    margin-bottom: 0;
  }

  .tema2dButtonTrue {
    margin-top: 10px;
  }

  .tema2dButtonTrue button {
    font-size: 9px;
  }

  .temaContentBoxTrue h3 {
    font-size: 15px;
  }

  .temaContentBoxFalse h3 {
    font-size: 15px;
  }

  .oracleSectionHeight {
    margin: 20px 20px 42px 20px;
  }

  .overflowOracle {
    padding-top: 0;
  }

  .secondSliderContent {
    padding: 10px 30px 30px 30px;
  }

  .backBtnSlider {
    color: #ffffff;
    justify-content: flex-start;
  }

  .overflowOracle {
    overflow: visible;
    height: 100%;
    position: relative;
    padding-bottom: 10px;
  }

  .firstDiv1 {
    z-index: 2;
    width: 80%;
    text-align: center;
  }

  .firstDivSliderFirst {
    min-height: 20px;
    /* margin-bottom: 22px; */
    /* margin-top: 8px; */
  }

  .collapseCard div#collapseExample .card.card-body button {
    color: #ffffff;
  }

  .secondSliderContent div#v-pills-tabContent div#v-pills-home h2 {
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .secondSliderContent ul.slider.animated li.slide.selected h2 {
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .projectDetailSlider h4,
  h5 {
    font-size: 15px;
  }

  .projectDetailSlider svg {
    width: 12px;
  }

  .oracleFirstDiv,
  .firstDivSlidersecond,
  .oraclefirstDivTranslate,
  .oracleSecondDiv,
  .oracleSecondDivTranslate,
  .firstDivSlidersecondAnimation,
  .firstDivSliderThird,
  .firstDivSliderThirdAnimation,
  .oracleSection,
  .oracleSectionHeight {
    transition: 0s;
  }

  /* .secondSliderContent .carousel .control-dots{
        bottom: 34%;
    } */
  .signNavbarSecond {
    margin-bottom: 5px;
    margin-top: -5px;
    padding-left: 43px;
  }

  ul.signNavbarSecond li.nav-item a.nav-link svg {
    height: 20px;
  }

  .mainNavBar a.navbar-brand {
    /* width: 82%; */
    display: flex;
    justify-content: center;
  }

  .mainHomePage .mainNavBar nav.navbar.navbar-expand-lg {
    padding: 14px 10px;
  }

  /* .autoplay-video .rh5v-DefaultPlayer_component.videoPlayer .rh5v-DefaultPlayer_video {
        height: 373px;
        object-fit: cover;
    } */

  .projectDetailSlider {
    top: 41%;
    /* margin-left: 8px; */
  }

  .projectDetailSlider h1 {
    font-size: 20px;
    padding: 0 20px;
  }

  .projectBtn {
    padding: 5px 23px;
    font-size: 12px;
  }

  .mainHomePage .carousel .control-dots .dot {
    width: 12%;
  }

  /* .agentSection,
    .developerSection {
        margin-top: 5px;
    } */

  .mainNavBar nav.navbar.navbar-expand-lg .container-fluid a.navbar-brand {
    margin-left: 25px;
  }

  /* .autoplay-video .container {
        bottom: 33% !important;
    } */

  /* .autoplay-video .container {
        bottom: 9% !important;
    } */
}

@media (max-width: 414px) {
  .oracleSectionHeight {
    margin: 20px 10px 24% 10px;
  }

  .rh5v-DefaultPlayer_component {
    height: 327px;
  }
}

@media (max-width: 375px) {
  /* .oracleSectionHeight {
        margin: 20px 20px 40px 20px;
    } */

  /* .wrapper {
        height: 25vh;
    } */

  .tema2dContentDescriptionTrue p,
  .tema2dContentDescriptionFalse p {
    font-size: 8px;
  }

  .temaContentBoxTrue h3,
  .temaContentBoxFalse h3 {
    font-size: 12px;
  }

  .tema2dButtonTrue button,
  .tema2dButtonFalse button {
    font-size: 7px;
  }
}

@media (max-width: 360px) {
  .projectDetailSlider h1 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .projectDetailSlider h4,
  h5 {
    margin-bottom: 10px;
  }

  /* .autoplay-video .container {
        bottom: 45%;
    } */
}

@media (max-width: 760px) {
  /* .autoplay-video .container {
        top: unset;
        bottom: 46%;
    } */
}

@media (max-height: 754px) {
  /* .autoplay-video .container {
        bottom: 34%;
    } */
}

/* @media (max-height: 700px) {
    .autoplay-video .container {
        bottom: 36%;
    }
} */

@media (max-height: 720px) {
  /* .autoplay-video .container {
        bottom: 31%;
    } */
}

@media (max-height: 654px) {
  .autoplay-video .container {
    /* bottom: 26%; */
  }

  /* .socialIconsDiv {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    } */

  .enrollmentModal {
    /* height: 500px;
        overflow: scroll; */
    position: relative;
  }
}

@media (max-height: 564px) {
  .autoplay-video .container {
    /* bottom: 30%; */
  }
}

@media (max-height: 500px) {
  .autoplay-video .container {
    /* bottom: 33%; */
  }
}

.mimpm_banner_section {
  position: relative;
  /* background: url('../public/assets/images/mpim/banner_image.png'); */
  height: auto;
  width: 100%;
}

.mimpm_section a {
  text-decoration: none;
  color: #ffffff;
}

.banner_content {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 65px;
}

.mimpm_banner_section .rh5v-DefaultPlayer_component {
  background: transparent;
}

.mimpm_banner_section .rh5v-Overlay_component {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 99.4%;
  width: 100%;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.3);
}

.image_section_card .ant-image.css-dev-only-do-not-override-j0nf2s {
  width: 100%;
}

.mimpm_banner_section .rh5v-DefaultPlayer_component {
  width: 100%;
  height: 100%;
}

.banner_heading {
  margin-bottom: 50px;
}

.banner_content .cubedots_image img:nth-child(1) {
  width: 32%;
}

.banner_content .cubedots_image img:nth-child(2) {
  width: 20%;
}

.our_client_section {
  padding: 60px 0;
}

.bookImage {}

.our_client_section img {
  margin: 0 20px;
}

.cubedots_footer {
  text-align: center;
  background: #001c32;
}

.banner_btn {
  gap: 10px;
  left: 585px;
  top: 4472px;
  background: #ea611d;
  backdrop-filter: blur(3.5px);
  border-radius: 48px;
  width: 36%;
  height: 43px;
  color: #ffffff;
  border-color: transparent;
  margin-top: 62px;
  font-size: 18px;
  font-weight: 500;
}

.book_live_demo_btn:hover path {
  fill: #ea611d;
}

.email_id {
  text-decoration: underline;
}

.mimpm_banner_section a svg {
  margin-right: 10px;
  margin-bottom: 3px;
}

button.book_live_demo_btn svg {
  margin-right: 10px;
  margin-bottom: 3px;
}

button.book_live_demo_btn {
  gap: 10px;
  left: 585px;
  top: 4472px;
  background: #ea611d;
  backdrop-filter: blur(3.5px);
  border-radius: 48px;
  min-width: 23%;
  height: 43px;
  color: #ffffff;
  border-color: transparent;
  margin-top: 30px;
  font-size: 18px;
  font-weight: 500;
}

.image_section {
  padding: 60px 0 30px 0;
}

.empowering_section {
  padding: 60px 0;
}

.banner_content h6 {
  font-size: 35px;
  color: #ffffff;
  margin-bottom: 40px;
  margin-bottom: 5px;
}

.empowering_section h6,
.who_heading h6,
.our_client_section h6 {
  font-size: 30px;
  color: #ea611d;
  margin-bottom: 20px;
  font-weight: 600;
}

.empowering_section h5 {
  font-size: 16px;
  color: #ea611d;
  font-weight: 700;
  margin-top: 30px;
  line-height: 1.4;
  text-transform: uppercase;
}

.our_client_section .ourPartners h3 {
  display: none;
}

.icons_section img.img-fluid {
  width: 60px;
  height: 75px;
  margin-bottom: 10px;
}

.icons_section small {
  color: #001c32;
  font-size: 16px;
  font-weight: 700;
}

.who_we_are_card_content {
  margin-bottom: 30px;
}

.who_we_are_card_content h6 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 12px;
}

.who_we_are_card_content small {
  color: #2b2c43;
  opacity: 0.5;
  font-size: 16px;
}

.who_we_are_card_content p {
  color: #ea611d;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
}

.who_we_are_card {
  margin-top: 30px;
}

.book_live_demo_btn:hover {
  background-color: transparent;
  color: #ea611d;
  border: 1px solid #ea611d;
}

.book_live_demo_btn:hover a {
  color: #ea611d;
}

.banner_btn:hover {
  background-color: transparent;
  color: #ea611d;
  border: 1px solid #ea611d;
}

.banner_btn:hover path {
  fill: #ea611d;
}

.banner_btn:hover a {
  color: #ea611d;
}

.who_we_are_card .carousel img {
  width: unset;
}

.who_we_are_section .carousel-control-prev-icon {
  background: url(../public/assets/images/mpim/arrow-right-circle.png);
  background-repeat: no-repeat;
}

.who_we_are_section .carousel-control-next-icon {
  background: url(../public/assets/images/mpim/arrow-left.png);
  background-repeat: no-repeat;
}

.who_we_are_section .who_we_are_card .card_image {
  width: 50%;
}

.image_section_card {
  position: relative;
}

.image_section_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  opacity: 0;
  transition: 0.4s;
  cursor: pointer;
}

.image_section_card:hover .image_section_overlay {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.ReactModal__Content.ReactModal__Content--after-open.mpModal {
  width: 80%;
  border-radius: 0 !important;
  border-color: transparent;
}

.ReactModal__Content.ReactModal__Content--after-open.mpModal img {
  border-radius: 10px;
}

.ReactModal__Content.ReactModal__Content--after-open.mpModal .carousel-control-next,
.carousel-control-prev {
  opacity: 1;
  /* width: 4%; */
}

.ReactModal__Content.ReactModal__Content--after-open.mpModal .carousel-inner {
  border-radius: 10px;
}

.image_section_overlay h6 {
  font-size: 30px;
  font-weight: 600;
}

@media (max-width: 1024px) {
  .banner_content {
    margin-top: 0;
  }

  .banner_content h6 {
    font-size: 30px;
  }
}

@media (max-width: 920px) {
  .banner_content {
    top: 30px;
  }
}

@media (max-width: 824px) {
  .banner_heading {
    margin-bottom: 10px;
  }

  .banner_btn {
    margin-top: 15px;
  }
}

@media (max-width: 656px) {
  .banner_content .cubedots_image img:nth-child(2) {
    width: 20%;
  }

  .banner_content .cubedots_image img:nth-child(1) {
    width: 30%;
  }

  .banner_content h6 {
    font-size: 18px;
  }

  .banner_btn {
    width: 43%;
  }
}

@media (max-width: 500px) {
  .mimpm_banner_section .rh5v-Overlay_component {
    height: 98%;
  }

  .banner_content h6 {
    font-size: 12px;
  }

  .banner_content .cubedots_image img:nth-child(2) {
    width: 15%;
  }

  .banner_btn {
    width: 43%;
    height: 30px;
    font-size: 12px;
  }

  .empowering_section {
    padding: 30px 20px;
  }

  .icons_section {
    padding: 0 20px;
  }

  .our_client_section {
    padding: 50px 20px 0px 20px;
  }

  .who_we_are_section {
    padding: 0 20px;
  }
}

.captcha-code-disable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Large devices (laptops/desktops, 992 to 1200px) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .turkish ul.navbar-nav.navbarMenu li.nav-item a.nav-link {
    font-size: 10px;
    padding: 8px 4px;
  }

  .turkish .navbarMenu .dropdown a {
    font-size: 10px;
  }

  .english ul.navbar-nav.navbarMenu li.nav-item a.nav-link {
    font-size: 10px;
    padding: 8px 5px;
  }

  .english .navbarMenu .dropdown a {
    font-size: 10px;
  }
}

@media (max-width: 576px) and (orientation: portrait) {
  .rh5v-DefaultPlayer_video {
    object-fit: unset;
  }
}

@media (min-width: 300px) and (max-width: 500px) {
  .page-spin-loading {
    margin-left: 10%;
    margin-top: 28%;
  }
}

@media (min-width: 500px) and (max-width: 700px) {
  .page-spin-loading {
    margin-left: 8%;
    margin-top: 18%;
  }
}


@media (min-width: 700px) and (max-width: 950px) {
  .page-spin-loading {
    margin-left: 7%;
    margin-top: 15%;
  }
}

@media (min-width: 950px) and (max-width: 1200px) {
  .page-spin-loading {
    margin-left: 5%;
    margin-top: 12%;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .page-spin-loading {
    margin-left: 4%;
    margin-top: 10%;
  }
}

@media (min-width: 1400px) {
  .page-spin-loading {
    margin-left: 4%;
    margin-top: 8%;
  }
}

@media (min-width: 1800px) {
  .page-spin-loading {
    margin-left: 3%;
    margin-top: 5%;
  }
}