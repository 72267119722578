/*Project List Scss*/
$theme-color: #043e55;
$main-heading-font-size: 30px;
$paragraph-font-size: 15x;
$color: #fff;
$border-radius: 10px;
$text-center: center;
$padding-top: 60px;



.projectListSection {
    background-color: $theme-color;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: $padding-top;
}

.projectListData {
    border: 1px solid $color;
    margin: 10px;
    border-radius: $border-radius;
    text-align: $text-center;

    img {
        border-radius: $border-radius;
        position: relative;
        width: 100%;
        height: 100%;

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
        }
    }

    h3 {
        font-size: $main-heading-font-size;
        color: $color;
        margin-bottom: 10px;
    }

    p {
        font-size: $paragraph-font-size;
        color: $color;
    }
}

@media (max-width:500px) {
    .projectListSection {
        height: 100%;
    }
}

/**/