
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200&display=swap');

$mainFontSize: 62px;
$mainFontWeight: 700;
$smallFontWeight: 800;
$paraFontSize: 17px;
$inputFontSize: 15px;
$buttonFontSize: 18px;
$fontColor : #fff;
$font-family-Roboto: 'Poppins', sans-serif;
$font-family-Noto: 'Poppins', sans-serif;
$font-Family-Dm-sans :'Poppins', sans-serif;

body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.temaSection {

    .tema_Landing_page_main {
        div#exampleModal .modal-dialog .modal-content{
            padding: 25px;
        }
        div#exampleModal .modal-dialog {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .form-area {
            background-color: #fff;
            box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
            padding: 40px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .form-area .form-inner {
            width: 100%;
        }

        .form-control:focus {
            color: #475F7B;
            background-color: #FFF;
            border-color: #5A8DEE;
            outline: 0;
            box-shadow: 0 3px 8px 0 rgb(0 0 0 / 10%);
        }

        .intl-tel-input,
        .iti {
            width: 100%;
        }

        .form_box {
            width: 90%;
        }

        /*popup*/
        .okBtn {
            background: #EA611D;
            border: none;
            padding: 6px 50px;
            color: #fff;
            border-radius: 5px;
            margin-top: 12px;
        }

        .modal-content .modal-body {
            padding: 0;
            margin-top: 50px;
            text-align: center;
        }

        .modal-dialog .modal-content p {
            position: absolute;
            left: 44%;
            top: -7%;
        }

        .tema_istanbul_main_section div#exampleModal .modal-dialog {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .tema_istanbul_main_section .modal-dialog .modal-content {
            width: 73%;
            height: 202px;
        }

        /*popup*/
        /*main_banner*/
        .main_banner_carousel .carousel-indicators {
            position: absolute;
            right: -73px;
            bottom: unset;
            left: unset;
            z-index: 3;
            display: unset;
            justify-content: unset;
            padding: 0;
            margin-right: unset;
            margin-bottom: unset;
            margin-left: unset;
            list-style: none;
            transform: rotate(90deg);
            top: 29%;
        }

        .main_banner_carousel .carousel-indicators button.active {
            color: black;
        }

        .main_banner_carousel .carousel-indicators [data-bs-target] {
            box-sizing: content-box;
            flex: 0 1 auto;
            width: 50px;
            height: 4px;
            padding: 0;
            margin-right: -5px;
            margin-left: 0px;
            text-indent: 0px;
            cursor: pointer;
            background-color: #fff;
            background-clip: padding-box;
            border: none;
            border-top: none;
            border-bottom: none;
            opacity: 0.5;
            transition: opacity .6s ease;
        }

        .main_banner_carousel .carousel-indicators button {
            position: relative;
        }

        .main_banner_carousel .carousel-indicators .active {
            opacity: 1;
        }

        .main_banner_carousel h3 {
            transform: rotate(-90deg);
            position: absolute;
            left: -7px;
            top: 68px;
            color: #fff;
            font-size: 20px;
            font-weight: normal;
        }

        .main_banner_carousel .carousel-indicators #btn1::before {
            content: "Start";
            font-size: 17px;
            color: rgb(255, 255, 255);
            transform: rotate();
            transform: rotate(-90deg);
            position: absolute;
            top: 45px;
            right: 3px;
        }

        .main_banner_carousel .carousel-indicators #btn2::before {
            content: "01";
            font-size: 17px;
            color: rgb(255, 255, 255);
            transform: rotate();
            transform: rotate(-90deg);
            position: absolute;
            top: 33px;
            right: 12px;
        }

        .main_banner_carousel .carousel-indicators #btn3::before {
            content: "02";
            font-size: 17px;
            color: rgb(255, 255, 255);
            transform: rotate();
            transform: rotate(-90deg);
            position: absolute;
            top: 33px;
            right: 12px;
        }

        .main_banner_carousel .carousel-indicators #btn4::before {
            content: "03";
            font-size: 17px;
            color: rgb(255, 255, 255);
            transform: rotate();
            transform: rotate(-90deg);
            position: absolute;
            top: 33px;
            right: 12px;
        }

        .main_banner_carousel .carousel-indicators #btn5::before {
            content: "04";
            font-size: 17px;
            color: rgb(255, 255, 255);
            transform: rotate();
            transform: rotate(-90deg);
            position: absolute;
            top: 33px;
            right: 12px;
        }

        /*main_banner*/



        /* image section*/
        .image_section_footer1 .carousel-indicators {
            position: absolute;
            right: 15px;
            bottom: unset;
            left: unset;
            z-index: 2;
            display: unset;
            justify-content: unset;
            padding: 0;
            margin-right: unset;
            margin-bottom: unset;
            margin-left: unset;
            list-style: none;
            transform: rotate(90deg);
            top: 50%;
        }

        .image_section_footer1 .carousel-indicators button.active {
            color: black;
        }

        .image_section_footer1 .carousel-indicators [data-bs-target] {
            box-sizing: content-box;
            flex: 0 1 auto;
            width: 50px;
            height: 4px;
            padding: 0;
            margin-right: -5px;
            margin-left: 0px;
            text-indent: 0px;
            cursor: pointer;
            background-color: #fff;
            background-clip: padding-box;
            border: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            opacity: 0.5;
            transition: opacity 0.6s ease;
        }

        .image_section_footer1 .carousel-indicators button {
            position: relative;
        }

        .image_section_footer1 .carousel-indicators .active {
            opacity: 1;
        }

        .image_section_footer1 h3 {
            transform: rotate(-90deg);
            position: absolute;
            left: -12px;
            top: 68px;
            color: #fff;
            font-size: 20px;
            font-weight: normal;
        }

        .image_section_footer1 .carousel-indicators #btn1::before {
            content: "Start";
            font-size: 17px;
            color: rgb(255, 255, 255);
            transform: rotate();
            transform: rotate(-90deg);
            position: absolute;
            top: 35px;
            right: 14px;
        }

        .image_section_footer1 .carousel-indicators #btn2::before {
            content: "01";
            font-size: 17px;
            color: rgb(255, 255, 255);
            transform: rotate();
            transform: rotate(-90deg);
            position: absolute;
            top: 33px;
            right: 14px;
        }

        /* image section*/


        div#carouselExampleIndicators .carousel-indicators button {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: rgba(255, 255, 255, .5);
            border: 0;
        }

        div#carouselExampleIndicators .carousel-indicators button.active {
            background-color: #EA611D;
        }

        .tema_istanbul_main_section {
            height: auto;
            width: 100%;
            background: #F2F1E9;
        }

        div#carouselExampleIndicatorss .carousel-indicators button {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.5);
            border-top: 0;
            border-bottom: 0;
        }

        div#carouselExampleIndicatorss .carousel-indicators button.active {
            background-color: #EA611D;
        }

        nav#desktop-nav {
            position: absolute;
            top: 21%;
            right: 70px;
            z-index: 1;

            ul {
                li {
                    list-style: none;
                    margin: 26px 10px 0 0;
                    font-size: 14px;
                    font-weight: 500;
                    text-align: center;

                    a {
                        color: $fontColor;
                        text-decoration: none;
                    }
                }
            }
        }

        article.position-relative.image_section_footer .progressBarContainer {
            position: absolute;
            bottom: 20px;
            width: 300px;
            transform: rotate(90deg);
            /* right: 27%; */
            top: 0;
            right: 16%;
            z-index: 1;
        }

        nav#desktop-nav::after {
            content: "";
            position: absolute;
            width: 3px;
            height: 90%;
            background: #99979787;
            top: 15px;
            right: -6px;
        }

        nav#desktop-nav_image_section {
            position: absolute;
            top: 30%;
            right: 50px;
            z-index: 1;

            ul {
                li {
                    list-style: none;
                    margin: 26px 16px 0 0;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: end;

                    a {
                        color: $fontColor;
                        text-decoration: none;
                    }
                }
            }
        }

        nav#desktop-nav::before {
            content: "";
            width: 5px;
            height: 15px;
            background: #fff;
            top: 0;
            position: absolute;
            width: 3px;
            height: 31%;
            top: 15px;
            right: -6px;
            z-index: 2;
        }

        nav#desktop-nav_image_section::after {
            content: "";
            position: absolute;
            width: 3px;
            height: 85%;
            background: #99979787;
            top: 9px;
            right: -6px;
        }

        nav#desktop-nav_image_section::before {
            content: "";
            position: absolute;
            width: 3px;
            height: 39%;
            background: #fff;
            top: 9px;
            right: -6px;
            z-index: 2;
        }

        .main_banner_image_section {
            position: absolute;
            top: 0;
            padding: 24px 0;
            width: 100%;
            height: 100%;
        }

        .social_icons {
            position: absolute;
            top: 25%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            // left: -1.5666%;
            z-index: 3;

            p {
                transform: rotate(90deg);
                color: $fontColor;
                font-weight: $mainFontWeight;
                font-size: 14px;
            }
        }

        .social_icons_image {
            margin-top: 15px;

            img {
                margin-top: 15px;
                width: 18px;
            }
        }

        select#countrycode2.form-control {
            color: rgba(0, 0, 0, 0.6);
        }

        .main_banner_content .form-control {
            border-radius: 3px;
            color: rgba(0, 0, 0, 0.5);
            padding: 7px 21px;
            font-size: 13px;
        }

        ::-webkit-input-placeholder {
            font-weight: 400;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.5);
        }

        #companyname2.form-control {
            font-weight: 400;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.5);
            padding: 8.999px;
        }

        .main_banner_content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 7% 0 27% 0;

            .main_banner_content_box_section {
                margin-bottom: 72px;
            }

            img {
                width: 40%;
            }

            small {
                font-size: 12px;
                color: $fontColor;
                position: relative;
                margin-left: 80px;
                font-family: $font-family-Roboto;
                letter-spacing: 5px;
                font-weight: 600;
            }

            small::before {
                content: "";
                position: absolute;
                width: 50px;
                height: 1px;
                background: #FFFFFF;
                left: -80px;
                bottom: 50%;
            }

            h1 {
                font-size: $mainFontSize;
                color: #F2F1E9;
                font-family: $font-family-Noto;
                font-weight: $mainFontWeight;
            }

            p {
                font-size: $paraFontSize;
                color: $fontColor;
                font-weight: $mainFontWeight;
            }

            button:nth-child(1) {
                margin-top: 3%;
                background: #FBD784;
                outline: none;
                color: #fff;
                width: 26%;
                outline: none;
                border: none;
                border-radius: 3px;
                padding: 8px 0px;
                font-family: "Roboto", sans-serif;
                font-weight: 400;
                font-size: 12px;
                letter-spacing: 1.05px;
            }
        }

        .location_btn {
            background: #EA611D;
            border-radius: 3px;
            width: 20%;
            border: none;
            color: #fff;
            margin-bottom: 2%;
            height: 40px;
            font-weight: 900;
            font-size: 14px;
            letter-spacing: 1.05px;
        }

        .phone_col {
            padding-right: 0;
        }

        .email_col {
            padding-left: 0;
        }

        .col-md-4.name_col {
            padding-right: 0;
        }


        .col-md-4.code_col {
            padding-right: 0;
            padding-left: 5px;
        }

        .col-md-4.email_col {
            padding-left: 5px;
        }

        .family_friendly_main_section ol.carousel-indicators li {
            position: relative;
            -webkit-box-flex: 0;
            -ms-flex: 0 1 auto;
            flex: 0 1 auto;
            width: 10px;
            height: 10px;
            margin-right: 3px;
            margin-left: 3px;
            text-indent: -999px;
            background-color: #DADADA;
            ;
            border-radius: 50%;
        }

        .family_friendly_main_section .carousel-indicators .active {
            background-color: #EA611D;
        }

        .family_friendly_box {
            padding: 0 43px;

            img {
                width: 50px;
            }

            p {
                font-size: 12px;
                font-weight: 400;
                color: #999999;
            }

            h6 {
                color: #02731E;
                margin-top: 20px;
                font-size: 20px;
                font-weight: 700;
                font-family: $font-Family-Dm-sans;
            }
        }

        .ideal_content {
            margin-top: 30px;
            // margin-right: 25px;

            h1 {
                font-size: 55px;
                color: #02731E;
                padding: 20px 0;
                font-family: $font-family-Noto;
                font-weight: $mainFontWeight;
            }

            small {
                font-size: 12px;
                // color: $fontColor;
                position: relative;
                margin-left: 71px;
                font-family: $font-family-Roboto;
                letter-spacing: 5px;
                font-weight: 600;
                color: #999999;
            }

            p {
                color: #999999;
                font-size: 15px;
                font-weight: 500;
                line-height: 24px;
            }

            small::before {
                content: "";
                position: absolute;
                width: 50px;
                height: 1px;
                background: #999999;
                left: -71px;
                bottom: 50%;
            }
        }

        .family_friendly_main_section {
            padding: 12px 0;
            text-align: center;
        }

        .family_friendly_main_section .carousel-indicators {
            bottom: -31px;
        }

        .ideal_image {
            position: relative;
            top: -37px;
            left: 50px;

            img {
                width: 442px;
            }

            a {
                position: fixed;
                top: 88%;
                /* left: revert; */
                /* right: -52%; */
                width: 70px;
                cursor: pointer;
                right: 68px;
                z-index: 1;
            }
        }

        .cubedots_last {
            padding: 20px;
            text-align: center;
        }

        .family_friendly_main_section ol.carousel-indicators {}

        @media (max-width:1400px) {
            .ideal_image a {
                position: fixed;
                top: 50%;
                /* left: revert; */
                /* right: -52%; */
                width: 70px;
                cursor: pointer;
                right: 68px;
                z-index: 1;
            }

            .ideal_content h1 {
                font-size: 45px;
                color: #02731E;
                padding: 20px;
                font-family: "Noto Serif Display", serif;
                font-weight: 700;
            }

            .main_banner_content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 2% 0 27% 0;
            }

            .main_banner_content .main_banner_content_box_section {
                margin-bottom: 40px;
            }
        }

        @media (max-width:1366px) {
            .main_banner_content {
                .main_banner_content_box_section {
                    margin-bottom: 71px;
                }

                img {
                    width: 40%;
                }

                h1 {
                    font-size: 52px;
                }

                p {
                    font-size: 16px;
                }
            }
        }

        @media (max-width:1150px) {
            .main_banner_content {
                padding: 2% 0 11% 0;
            }
        }

        @media (max-width:1024px) {
            .progressBarContainer h3 {
                bottom: 96%;
            }

            .image_section_footer .progressBarContainer h6 {
                bottom: 88%;
            }

            .image_section_footer .progressBarContainer h3 {
                bottom: 89%;
            }

            .progressBarContainer h6 {
                bottom: 95%;
            }

            .main_banner_content {
                .main_banner_content_box_section {
                    margin-bottom: 60px;
                }

                img {
                    width: 36%;
                }

                h1 {
                    font-size: 44px;
                }

                p {
                    font-size: 16px;
                }
            }

            .ideal_image {
                position: relative;
                top: -37px;
                left: 50px;

                img {
                    width: 120%;
                }

                a {
                    position: fixed;
                    top: 30%;
                    /* left: revert; */
                    /* right: -52%; */
                    width: 70px;
                    cursor: pointer;
                    right: 22px;
                    z-index: 1;
                }

                //     position: fixed;
                //     top: 18%;
                //     /* left: revert; */
                //     right: -52%;
                //     width: 70px;
                //     cursor: pointer;
                // }
            }

            .ideal_content {
                margin-top: 30px;

                h1 {
                    font-size: 39px;
                    padding: 15px 0;
                }

                p {
                    font-size: 12px;
                    line-height: 18px;
                }
            }

            .family_friendly_main_section {
                padding-top: 30px;
            }

            .family_friendly_box {
                padding: 0 10px;
            }
        }

        @media (max-width:1024px) and (orientation:landscape) {
            .ideal_image a {
                top: 60%;
            }
        }

        @media (max-width: 812px) and (orientation: landscape) {
            .main_banner_content {
                .main_banner_content_box_section {
                    margin-bottom: 24px;
                }

                img {
                    width: 40%;
                }

                h1 {
                    font-size: 30px;
                }

                p {
                    font-size: 12px;
                }
            }

            .ideal_image a {
                top: 91%;
            }

            .progressBarContainer {
                right: 40%;
            }

            .progressBarContainer h3 {
                bottom: 94%;
            }

            .ideal_content {
                h1 {
                    font-size: 27px;
                }

                p {
                    font-size: 10px;
                    line-height: 14px;
                }
            }

            .family_friendly_box {
                padding: 0;
            }
        }

        // @media (max-width:800px) and (orientation:portrait) {
        //     .ideal_image {
        //         img {
        //             width: 70%;
        //             display: none;
        //         }

        //         a {
        //             display: none;
        //         }
        //     }
        // }
        @media (max-width:850px) {
            .family_friendly_box {
                padding: 0 0px;
            }

            .col-md-4.email_col {
                padding-right: 0;
            }

            .ideal_image {
                img {
                    width: 120%;
                }

                a {
                    width: 59px;
                }
            }

            .main_banner_content .main_banner_content_box_section {
                margin-bottom: 0;
            }

            .main_banner_content {
                padding: 2% 0 7% 0;
            }
        }

        @media (max-width:768px) {
            .family_friendly_box {
                padding: 0 0px;
            }

            .col-md-4.email_col {
                padding-right: 0;
            }

            .ideal_image {
                img {
                    width: 120%;
                }

                a {
                    top: 46%;
                    width: 59px;
                }
            }

            .main_banner_content .main_banner_content_box_section {
                margin-bottom: 0;
            }

            .main_banner_content {
                padding: 2% 0 7% 0;
            }
        }



        @media (max-width: 667px) and (orientation: landscape) {
            .ideal_image {
                top: 18px;

                img {
                    width: 80%;
                    top: 50px;
                }

                a {
                    display: none;
                }
            }

            .social_icons {
                display: none;
            }

            nav#desktop-nav {
                display: none;
            }

            nav#desktop-nav_image_section {
                display: none;
            }
        }

        @media (max-width:767px) {
            .cubedots_logo_image {
                width: 32%;
            }

            .main_banner_section #carouselExampleIndicators .carousel-inner .img-fluid {
                height: 120vh;
                object-fit: cover;
            }

            // .main_banner_section {
            //     background-position: 33%;
            //     background-image: linear-gradient(329.24deg, rgba(26, 11, 38, 0) 31.82%, rgba(2, 115, 30, 0.3) 43.39%, rgba(242, 241, 233, 0.3) 108.38%), url(images/MainBgImage.png);
            // }

            .family_friendly_main_section {
                height: 230px;
            }

            .main_banner_content {
                .main_banner_content_box_section {
                    margin-bottom: 24px;
                }

                img {
                    width: 50%;
                    margin-bottom: 10px;
                }

                h1 {
                    font-size: 33px;
                    margin-top: 10px;
                    margin-bottom: 20px;
                }

                p {
                    font-size: 12px;
                }

                small {
                    color: #02731E;
                    margin-left: 50px;
                    font-weight: 800;
                    font-size: 14px;
                }

                small::before {
                    content: "";
                    position: absolute;
                    width: 40px;
                    height: 3px;
                    background: #02731E;
                    left: -50px;
                    bottom: 50%;
                }
            }

            .content_box {
                padding: 30px 40px;
            }

            .ideal_image {
                top: 0;
                left: 0;
                text-align: center;

                img {
                    width: 100%;
                }

                a {
                    display: none;
                }
            }

            .ideal_content {
                h1 {
                    font-size: 29px;
                }

                p {
                    font-size: 10px;
                    line-height: 18px;
                }
            }

            .family_friendly_box {
                padding: 0;
            }

            .main_image_section img {
                height: 338px;
                object-fit: cover;
                object-position: 74%;
            }

            .main_banner_content {
                padding: 7% 40px 27% 40px;
            }

            .code_col {
                padding-left: 12px;
                padding-right: 0px;
                margin-bottom: 5px;
            }

            .phone_col {
                padding-left: 12px;
                padding-right: 12px;
                margin-bottom: 5px;
            }

            .name_col {
                padding-right: 12px;
                padding-left: 12px;
                margin-bottom: 5px;
            }

            .col-md-3.email_col {
                padding-right: 12px;
                padding-left: 12px;
            }

            .location_btn {
                width: 42%;
                height: 45px;
            }

            .social_icons {
                display: none;
            }

            nav#desktop-nav {
                display: none;
            }

            nav#desktop-nav_image_section {
                display: none;
            }

            #email2-info,
            #phoneNumber2-info,
            #countrycode2-info,
            #firstName2-info {
                margin: 0;
            }
        }

        @media (max-width: 736px) and (orientation:landscape) {
            .main_banner_section #carouselExampleIndicators .carousel-inner .img-fluid {
                height: 230vh;
                -o-object-fit: cover;
                object-fit: cover;
            }

            p#txtPhone-info {
                margin: 0;
            }

            .col-md-4.name_col {
                padding-right: 0;
                padding-left: 5px;
            }
        }

        @media (max-width:500px) {
            .col-md-4.email_col {
                padding-right: 12px;
                padding-left: 12px;
            }

            .col-md-4.code_col {
                padding-right: 12px;
                padding-left: 12px;
            }

            .col-md-4.name_col {
                padding-right: 12px;
                padding-left: 12px;
            }

            .family_friendly_main_section .carousel-indicators {
                bottom: 0;
            }

            .cubedots_logo_image {
                width: 32%;
            }

            .form_box {
                width: 100%;
            }

            .main_banner_section #carouselExampleIndicators .carousel-inner .img-fluid {
                height: 120vh;
                object-fit: cover;
            }

            .family_friendly_main_section {
                height: 270px;
            }

            p#txtPhone-info {
                margin: 0;
            }

            .main_banner_content {
                .main_banner_content_box_section {
                    margin-bottom: 24px;
                }

                img {
                    width: 50%;
                    margin-bottom: 10px;
                }

                h1 {
                    font-size: 30px;
                    margin-top: 10px;
                    margin-bottom: 20px;
                }

                p {
                    font-size: 12px;
                }

                small {
                    color: #02731E;
                    margin-left: 50px;
                    font-weight: 800;
                    font-size: 12px;
                }

                small::before {
                    content: "";
                    position: absolute;
                    width: 40px;
                    height: 3px;
                    background: #02731E;
                    left: -50px;
                    bottom: 50%;
                }
            }

            .content_box {
                padding: 30px 40px;
            }

            .ideal_image {
                top: 0;
                left: 0;
                text-align: center;

                img {
                    width: 100%;
                }

                a {
                    display: none;
                }
            }

            .ideal_content {
                h1 {
                    font-size: 29px;
                }

                p {
                    font-size: 10px;
                    line-height: 18px;
                }
            }

            .family_friendly_box {
                padding: 0;
            }

            .main_image_section img {
                height: 338px;
                object-fit: cover;
                object-position: 74%;
            }

            .main_banner_content {
                padding: 7% 40px 6% 40px;
            }

            .code_col {
                padding-left: 12px;
                padding-right: 0px;
                margin-bottom: 5px;
            }

            .phone_col {
                padding-left: 12px;
                padding-right: 12px;
                margin-bottom: 5px;
            }

            .name_col {
                padding-right: 12px;
                padding-left: 12px;
                margin-bottom: 5px;
            }

            .col-md-3.email_col {
                padding-right: 12px;
                padding-left: 12px;
            }

            .location_btn {
                width: 42%;
                height: 45px;
            }

            .social_icons {
                display: none;
            }

            nav#desktop-nav {
                display: none;
            }

            nav#desktop-nav_image_section {
                display: none;
            }

            #email2-info,
            #phoneNumber2-info,
            #countrycode2-info,
            #firstName2-info {
                margin: 0;
            }
        }
    }
}