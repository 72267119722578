@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200&display=swap');



.text-image-scroll-viewer {
    position: relative;
    padding-bottom: 100px;
    display: flex;
    width: 100%
}

.text-wrapper {
    height: fit-content;
    width: 50%;
    margin-right: auto
}

@media screen and (max-width:824px) {
    .text-wrapper {
        width: 100%
    }
}
body{
    font-family: 'Poppins', sans-serif;
}
.slide-text-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;

    .hero-content {
        h5 {
            font-size: 28px;
            font-weight: 700;
            margin-bottom: 20px;
            font-family: inherit;
        }

        p {
            color: #262626;
            font-size: 16px;
            line-height: 28px;
            opacity: .8;
            text-align: justify;
            font-family: inherit;
        }
    }
}

@media screen and (device-width:1024px)and (device-height:1366px)and (orientation:portrait)and (-webkit-min-device-pixel-ratio:2) {
    .slide-text-container {
        height: auto !important
    }
}

.text-wrapper-inner-wrapper {
    display: flex;
    flex-direction: column;
    text-align: left;
    max-width: 500px;
    min-height: 250px;
    justify-content: space-between;
    word-break: break-word;
    width: 80%
}

@media only screen and (max-width:850px)and (orientation:landscape) {
    .text-wrapper-inner-wrapper h2 {
        font-size: 27px !important
    }

    .text-wrapper-inner-wrapper p {
        font-size: 14px !important;
        margin-top: 0 !important
    }

    .custom-action-outer-wrapper,
    .text-wrapper-inner-wrapper div:nth-child(3) {
        margin-top: 0 !important
    }
}

.subheader {
    font-size: 36px;
    font-family: neue-haas-grotesk-text;
    font-weight: 700
}

@media only screen and (max-width:1139px) {
    .subheader {
        font-size: 24px
    }
}

.text-wrapper-inner-wrapper .read-more-wrapper a {
    display: flex;
    text-decoration: none;
    color: #742bd6
}

.text-wrapper-inner-wrapper .read-more-wrapper a img {
    padding-right: 10px
}

.images-wrapper {
    width: 50%;
    transition: width .5s ease-in-out, background-color .5s;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    overflow: hidden
}

.images-wrapper-fixed-stop {
    bottom: 0;
    right: 0;
    position: absolute
}

.images-wrapper-fixed {
    position: fixed;
    top: 0;
    right: 0
}

.images-wrapper-fixed:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    height: 100%;
    width: 0;
    background: #fff;
    right: 50vw;
    z-index: 1;
    box-shadow: 0 2px 60px 80px #fff
}

@media only screen and (max-width:850px)and (orientation:landscape) {
    .images-wrapper-fixed {
        padding-top: 30px !important
    }
}

.images {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 50vw;
    position: relative;
    right: -15%;
}

@media all and (-ms-high-contrast:none),
(-ms-high-contrast:active) {
    .images {
        display: inline
    }
}

.images>img {
    width: 100%;
    transition: opacity 1s;
}

.custom-action-outer-wrapper {
    margin-top: 16px
}

.hero-content img {
    display: none
}

@media screen and (device-width:1024px)and (device-height:1366px)and (orientation:portrait)and (-webkit-min-device-pixel-ratio:2),
screen and (max-width:824px) {
    .text-image-scroll-viewer {
        padding-bottom: 0
    }

    .images-wrapper,
    .images-wrapper-fixed {
        display: none
    }

    .slide-text-container {
        height: auto
    }

    .hero-content {
        padding: 20px
    }

    .hero-content img {
        display: block;
        max-width: 100%
    }

    .text-wrapper {
        width: 100%
    }

}

@media screen and (device-width:1024px)and (device-height:1366px)and (orientation:portrait)and (-webkit-min-device-pixel-ratio:2),
screen and (max-width:1300px) {
    .devTrustContentImage {
        margin-top: 25%;
    }
}

.section-heading {
    font-size: 16px;
    margin: 40px 0;
    text-transform: uppercase;
    padding: 12px 10px;
    border-left: 4px solid #dc6641
}

.content-list-large {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 26px;
    line-height: 1.7
}

@media screen and (max-width:1024px) {
    .content-list-large li {
        font-size: 22px;
        margin: 15px 0;
        line-height: 1.5
    }
}

.bullet-content-why-us {
    display: none
}

@media screen and (max-width:824px) {
    .bullet-content-why-us {
        display: block;
        margin: 25px 0
    }

    .bullet-content-why-us ul {
        padding-left: 0
    }

    .bullet-content-why-us ul li {
        list-style: none;
        font-size: 22px;
        display: flex;
        align-items: center;
        margin: 15px 0
    }

    .bullet-content-why-us ul li img {
        height: 24px;
        width: 24px !important;
        margin-right: 20px
    }
}

#why-us .why-us-content {
    background-size: contain;
    min-height: 580px;
    display: flex;
    align-items: center
}

@media screen and (min-width:1000px) {
    #why-us .why-us-content {
        background-position: right bottom;
        background-size: 55%;
        min-height: 500px
    }
}

@media screen and (max-width:1100px) {
    #why-us .why-us-content {
        background-position: right bottom;
        background-size: 55%;
        min-height: 500px
    }
}

@media screen and (min-width:1300px) {
    #why-us .why-us-content {
        background-position: right bottom;
        background-size: 55%;
        min-height: 600px
    }
}

@media screen and (min-width:1700px) {
    #why-us .why-us-content {
        background-size: 50%;
        min-height: 640px
    }
}

@media screen and (min-width:2000px) {
    #why-us .why-us-content {
        background-size: 55%;
        min-height: 790px
    }
}

@media screen and (min-width:2300px) {
    #why-us .why-us-content {
        background-size: 55%;
        min-height: 900px
    }
}

@media screen and (min-width:2400px) {
    #why-us .why-us-content {
        min-height: 1050px;
        background-size: 55%
    }
}

@media screen and (max-width:1024px) {
    #why-us .why-us-content {
        background-position: right bottom;
        background-size: 55%;
        min-height: 500px
    }
}

@media screen and (max-width:824px) {
    #why-us .why-us-content {
        display: block;
        background-image: none;
        padding: 45px 10px
    }
}

#why-us img {
    width: 100%
}

.slide-text-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: flex-start
}

@media screen and (max-width:824px) {
    .slide-text-container {
        height: auto
    }
}

.hero-content {
    color: #393537;
    text-align: left;
    max-width: 480px;
    margin-left: 23%
}

@media screen and (max-width:824px),
screen and (device-width:1024px)and (device-height:1366px)and (orientation:portrait)and (-webkit-min-device-pixel-ratio:2) {
    .hero-content {
        margin: 0 auto;
        max-width: 100%;
        padding: 20px;
        text-align: center
    }

    .hero-content img {
        display: block;
        max-width: 100%
    }
}

.hero-content .main-heading {
    font-size: 48px;
    margin: 15px 0;
    line-height: 1.25;
    font-family: "Avenir Black"
}

@media screen and (max-width:824px) {
    .hero-content .main-heading {
        font-size: 28px
    }
}

@media screen and (min-width:992px)and (max-width:1024px) {
    .hero-content .main-heading {
        font-size: 28px
    }
}

.hero-content .sub-heading {
    font-size: 20px;
    font-family: "Avenir Black";
    margin: 10px 0
}

@media screen and (max-width:824px) {
    .hero-content .sub-heading {
        font-size: 18px
    }
}

.hero-content p {
    font-size: 18px
}

.hero-content .button {
    margin: 15px 0 0 0
}

@media screen and (max-width:824px) {
    .hero-content .button {
        margin: 5px 0 0 0
    }
}

.hero-content .image-slider {
    margin: 15px
}

@media screen and (max-width:824px) {
    .hero-content .image-slider {
        margin: 0 0 15px
    }
}

.hero-content .image-slider img {
    max-height: 470px
}

@media screen and (max-width:824px) {
    .hero-content .image-slider img {
        height: 100%;
        width: 100%
    }
}

.what-we-do {
    position: relative
}

.what-we-do .hero-what-we-do-container {
    margin: 55px 0;
    padding: 30px 0 150px 0
}

@media screen and (max-width:824px) {
    .what-we-do .hero-what-we-do-container .hero-what-we-do .slide {
        margin-bottom: 35px;
        border-bottom: 1px solid #eee
    }

    .what-we-do .hero-what-we-do-container .hero-what-we-do .slide:last-child {
        border-bottom: none
    }
}

.what-we-do .hero-what-we-do-container .hero-what-we-do .slide .list-slider {
    display: flex;
    justify-content: space-between;
    align-items: center
}

@media screen and (max-width:824px) {
    .what-we-do .hero-what-we-do-container .hero-what-we-do .slide .list-slider {
        flex-direction: column-reverse
    }
}

.what-we-do .hero-what-we-do-container .hero-what-we-do .slick-dots {
    bottom: unset;
    top: 50%;
    transform: translateY(-50%);
    left: -20px;
    width: 30px;
    display: inline
}

@media screen and (max-width:824px) {
    .what-we-do .hero-what-we-do-container .hero-what-we-do .slick-dots {
        left: -10px
    }
}

.what-we-do .hero-what-we-do-container .hero-what-we-do .slick-dots li {
    margin: 0 0 90px 0
}

.what-we-do .hero-what-we-do-container .hero-what-we-do .slick-dots li:last-child {
    margin-bottom: 0
}

.what-we-do .hero-what-we-do-container .hero-what-we-do .slick-dots li button:before {
    opacity: 1
}

.what-we-do .hero-what-we-do-container .hero-what-we-do .slick-dots li.slick-active button:before {
    opacity: 1
}

.what-we-do .hero-what-we-do-container .hero-what-we-do .slick-dots li:nth-child(3) {
    transform: rotate(-90deg)
}

.what-we-do .hero-what-we-do-container-v2 {
    margin: 55px 0;
    padding: 30px 0 150px 0
}

@media screen and (max-width:824px) {
    .what-we-do .hero-what-we-do-container-v2 .hero-what-we-do-v2 .slide {
        margin-bottom: 35px;
        border-bottom: 1px solid #eee;
        padding-bottom: 35px
    }

    .what-we-do .hero-what-we-do-container-v2 .hero-what-we-do-v2 .slide:last-child {
        border-bottom: none
    }
}

.what-we-do .hero-what-we-do-container-v2 .hero-what-we-do-v2 .slide .list-slider {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 100vh
}

@media screen and (max-width:824px) {
    .what-we-do .hero-what-we-do-container-v2 .hero-what-we-do-v2 .slide .list-slider {
        flex-direction: column-reverse;
        min-height: auto
    }
}

.what-we-do .hero-what-we-do-container-v2 .hero-what-we-do-v2 .slide .list-slider .item-slider {
    color: #393537;
    padding-left: 40px
}

@media screen and (max-width:824px) {
    .what-we-do .hero-what-we-do-container-v2 .hero-what-we-do-v2 .slide .list-slider .item-slider {
        padding-left: 0;
        margin: 0 25px
    }
}

@media screen and (max-width:824px) {
    .what-we-do .hero-what-we-do-container-v2 .hero-what-we-do-v2 .slide .list-slider .item-slider.content {
        margin-right: 0
    }
}

.what-we-do .hero-what-we-do-container-v2 .hero-what-we-do-v2 .slide .list-slider .item-slider .main-heading {
    font-size: 48px;
    margin: 15px 0;
    line-height: 1.25;
    font-family: "Avenir Black"
}

@media screen and (max-width:824px) {
    .what-we-do .hero-what-we-do-container-v2 .hero-what-we-do-v2 .slide .list-slider .item-slider .main-heading {
        font-size: 28px
    }
}

.what-we-do .hero-what-we-do-container-v2 .hero-what-we-do-v2 .slide .list-slider .item-slider .sub-heading {
    font-size: 20px;
    font-family: "Avenir Black";
    margin: 10px 0
}

@media screen and (max-width:824px) {
    .what-we-do .hero-what-we-do-container-v2 .hero-what-we-do-v2 .slide .list-slider .item-slider .sub-heading {
        font-size: 18px
    }
}

.what-we-do .hero-what-we-do-container-v2 .hero-what-we-do-v2 .slide .list-slider .item-slider p {
    font-size: 18px
}

.what-we-do .hero-what-we-do-container-v2 .hero-what-we-do-v2 .slide .list-slider .item-slider .button {
    margin: 15px 0 0 0
}

@media screen and (max-width:824px) {
    .what-we-do .hero-what-we-do-container-v2 .hero-what-we-do-v2 .slide .list-slider .item-slider .button {
        margin: 5px 0 0 0
    }
}

.what-we-do .hero-what-we-do-container-v2 .hero-what-we-do-v2 .slide .list-slider .item-slider .image-slider {
    margin: 15px
}

@media screen and (max-width:824px) {
    .what-we-do .hero-what-we-do-container-v2 .hero-what-we-do-v2 .slide .list-slider .item-slider .image-slider {
        margin: 0 0 15px
    }
}

.what-we-do .hero-what-we-do-container-v2 .hero-what-we-do-v2 .slide .list-slider .item-slider .image-slider img {
    max-height: 470px
}

@media screen and (max-width:824px) {
    .what-we-do .hero-what-we-do-container-v2 .hero-what-we-do-v2 .slide .list-slider .item-slider .image-slider img {
        height: 100%;
        width: 100%
    }
}

.what-we-do .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: 0 0;
    content: ""
}

#our-clients {
    background-size: contain;
    padding: 70px 0
}

@media screen and (max-width:824px) {
    #our-clients {
        padding: 30px 0
    }
}

@media screen and (min-width:320px)and (max-width:479px) {
    #our-clients {
        background-size: 300px
    }
}

#our-clients .content-our-clients {
    padding-top: 60px;
    max-width: 400px;
    font-size: 32px;
    text-align: left
}

@media screen and (max-width:824px) {
    #our-clients .content-our-clients {
        margin: 30px 0 15px 0;
        font-size: 20px;
        max-width: 250px
    }
}

@media screen and (min-width:320px)and (max-width:479px) {
    #our-clients .content-our-clients {
        max-width: 100%
    }
}

#our-clients .client-details {
    max-width: 40%;
    margin: 25px 0 25px 0
}

@media screen and (max-width:824px) {
    #our-clients .client-details {
        max-width: 100%;
        margin: 25px 0
    }
}

#our-clients .client-details .name {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 5px
}

#our-clients .client-details .designation {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25
}

.mouse-scroll-block {
    display: flex;
    align-items: center;
    margin-top: 50px
}

.mouse-scroll-block img {
    margin-right: 10px;
    height: 35px
}

.mouse-scroll-block .text-mouse-scroll {
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px
}

.bean-bullets {
    opacity: 0;
    position: fixed;
    transition: .3s;
    left: 0;
    top: 30%;
    z-index: 20
}

.bean-bullets.fixed {
    opacity: 1;
    transition: .2s;
    left: 30px
}

.bean-bullets .bullet {
    display: block;
    margin-bottom: 35px;
    height: 24px;
    width: 24px;
    margin-bottom: 75px;
    opacity: .3;
    transition: .1s;
    cursor: pointer
}

.bean-bullets .bullet:hover {
    opacity: 1
}

.bean-bullets .bullet.active {
    opacity: 1;
    transform: rotate(120deg)
}

.bean-bullets .bullet:nth-of-type(even) {
    margin-left: 30px
}

@media (max-width:1366px) {
    .hero-content {
        margin-left: 5%;
    }
}