@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200&display=swap');


body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;

}

.cityScapeLandingPage {
  font-size: 70px;
  margin-top: 65px;
}

@mixin overlayProperty {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

@mixin textCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
}

$border-dark: 5px 4px 10px rgba(0, 0, 0, 0.3);
$font-weight : 500;
$color: #33485b;
$font-common-size: 2.5rem;

.cityScapeLandingPage {

  ::placeholder {
    color: #fff;
    opacity: 1;
    font-size: 1.3rem;
    /* Firefox */
  }



  .headerSection {
    padding: 20px;

    img.scope {
      width: 90%;
    }
  }

  .bannerSection {
    background-image: url(../../../../public/assets/images/cityscape/1.jpg);
    width: 100%;
    height: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: right;
    position: relative;

    .bannerSection {
      @include textCenter;

      .bannerContent {
        z-index: 2;
        padding: 20px 0;
      }

      h6 {
        color: #fff;
        font-size: 4rem;
        text-align: center;
      }
    }

    .bannerSection::after {
      @include overlayProperty;
    }
  }

  .secondSection {
    background: #eeeeee;

    .imagesSection {
      box-shadow: $border-dark
    }

    .nisnatasiContent {
      @include textCenter;
      flex-direction: column;

      img {
        width: 42%;
        margin-bottom: 20px;
      }

      h6 {
        font-size: $font-common-size;
        font-weight: $font-weight;
        color: $color;
      }
    }
  }

  .thirdSection {
    .bestErupe {
      position: relative;
      height: 200px;

      .bestEurpeContent {
        display: flex;
        position: absolute;
        top: 0;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index: 2;
        padding: 0 40px;
        justify-content: space-between;

        .bestText {
          text-align: end;
        }

        h6 {
          color: #fff;
          font-size: 2rem;
        }

        img {
          width: 170px;
        }
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .bestErupe::after {
      @include overlayProperty;
    }

    .moderInfo {
      background-color: #dc6641;
      padding: 20px;

      img {
        width: 100%;
      }

      .moderInfoContent {
        h6 {
          font-size: 3rem;
          color: #fff;
          text-align: center;
          font-weight: $font-weight;
        }
      }
    }
  }

  .fourthSection {
    background-color: #eeeeee;

    .slylandImages {
      box-shadow: $border-dark
    }

    .fourthSectionContent {
      @include textCenter;
      flex-direction: column;

      div {
        margin-bottom: 30px;
      }

      img {
        width: 52%;
        margin-bottom: 40px;
      }

      h6 {
        font-size: 3rem;
        text-align: center;
        color: $color;
        font-weight: $font-weight;
      }
    }
  }

  .citizenShipSection {
    margin: 40px 20px;

    img.img-fluid.doller {
      width: 27%;
      left: -18%;
      position: absolute;
      top: -21%;
    }

    img.img-fluid.flag {
      width: 29%;
      height: 86px;
    }

    img.img-fluid.location,
    img.img-fluid.calender {
      width: 14%;
      height: 95px;
      object-fit: contain;
    }

    img.img-fluid.calender {
      margin-right: 20px;
      object-fit: contain;
    }

    img.img-fluid.passport {
      width: 14%;
      object-fit: contain;
    }

    img {
      width: 10%;
      object-fit: contain;
    }

    .bookPayment {
      background-color: #dc6641;
      position: relative;
      justify-content: end;

      h6 {
        font-size: $font-common-size;
        color: #fff;
        font-weight: $font-weight;
        line-height: 1;
      }
    }

    .elgibleCiti {
      justify-content: end;
      align-items: center;

      img {}

      h6 {
        font-size: $font-common-size;
        font-weight: 600;
        margin: 0 20px;
      }
    }

    .visitDetail {
      background-color: #dc6641;
      border-radius: 30px;
      padding: 20px 0;
      margin: 30px 0;

      .visitContent,
      .visitDate {
        justify-content: center;
        align-items: center;

        h6 {
          font-size: $font-common-size;
          color: #fff;
          font-weight: $font-weight;
          line-height: 0.8;
        }
      }
    }
  }

  .form_box {
    margin: 0 20px;

    .validationError {
      font-size: 12px;
      color: red;
    }

    span {
      border-radius: 50px;
      background: #33485b;
      padding: 8px;
      display: flex;
      flex-direction: column;
      width: 26%;
      margin: 20px auto;
      box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.3);
    }

    button {
      font-size: $font-common-size;
      font-weight: 600;
      border-radius: 50px;
      background: transparent;
      border-color: #ffffff;
      color: #fff;
    }

    button.submit_btn:hover {
      color: #dc6641;
    }

    .form-control {
      background: #33485b;
      color: #fff;
      padding: 14px;
      font-size: 1.3rem !important;
    }
  }

  .footerSection {
    span {
      display: flex;
      flex-direction: column;
      background: transparent;
      border-radius: 0;
      border: 1px solid;
    }

    img {
      width: 25px;
      height: 30px;
      object-fit: contain;
    }

    background-color: #f2f2f2;
    text-align: center;
    flex-direction: column;
    text-align: center;
    padding: 10px;

    h6 {
      font-size: 2rem;
      color: #24496a;
      margin: 5px 10px;
    }

    h6 a {
      text-decoration: none;
      color: #24496a;
    }

    h6 a:hover {
      color: #dc6641;
    }

    .footerLine {
      background-color: #24496a;
      height: 40px;
      width: 100%;
      margin: 20px 0;
    }
  }
}

@media (max-width:1366px) {
  .cityScapeLandingPage .citizenShipSection img.img-fluid.doller {
    width: 27%;
    left: -9%;
    position: absolute;
    top: -25%;
  }
}

@media (max-width:1199px) {
  .cityScapeLandingPage .citizenShipSection .bookPayment {
    justify-content: space-evenly;
  }

  .cityScapeLandingPage .citizenShipSection img.img-fluid.doller {
    position: unset;
    width: 10%;
  }

  .cityScapeLandingPage .citizenShipSection .elgibleCiti {
    margin-top: 20px;
    justify-content: center;
  }

  .cityScapeLandingPage .citizenShipSection img.img-fluid.passport {
    width: 8%;
  }
}

@media (max-width:1023px) {
  .cityScapeLandingPage .form_box button {
    font-size: 2rem;
  }

  .cityScapeLandingPage .footerSection h6 {
    margin: 5px;
  }

  .cityScapeLandingPage .bannerSection .bannerSection h6 {
    font-size: 2.5rem;
  }

  .cityScapeLandingPage .secondSection .nisnatasiContent h6 {
    font-size: 2rem;
  }

  .cityScapeLandingPage .thirdSection .bestErupe .bestEurpeContent h6 {
    font-size: 1.5rem;
  }

  .cityScapeLandingPage .fourthSection .fourthSectionContent {
    padding: 20px 0;
  }

  .cityScapeLandingPage .fourthSection .fourthSectionContent div {
    margin-bottom: 10px;
  }

  .cityScapeLandingPage .fourthSection .fourthSectionContent h6 {
    font-size: 1.5rem;
  }

  .cityScapeLandingPage .thirdSection .moderInfo .moderInfoContent h6 {
    font-size: 1rem;
  }
}

@media (max-width:767px) {
  .cityScapeLandingPage .thirdSection .bestErupe .bestEurpeContent h6 {
    font-size: 1.2rem;
  }

  .cityScapeLandingPage .thirdSection .bestErupe .bestEurpeContent {
    display: block;
    text-align: center;
  }

  .cityScapeLandingPage .thirdSection .bestErupe .bestEurpeContent .bestText {
    text-align: center;
  }

  .cityScapeLandingPage .secondSection .nisnatasiContent {
    padding: 20px 0;
  }

  .cityScapeLandingPage .thirdSection .moderInfo img {
    width: 28%;
  }

  .cityScapeLandingPage .thirdSection .moderInfo {
    text-align: center;
  }

  .cityScapeLandingPage .form_box span {
    width: auto;
  }

  .cityScapeLandingPage .citizenShipSection .bookPayment h6,
  .cityScapeLandingPage .citizenShipSection .elgibleCiti h6,
  .cityScapeLandingPage .citizenShipSection .visitDetail .visitContent h6,
  .cityScapeLandingPage .citizenShipSection .visitDetail .visitDate h6 {
    font-size: 1.5rem;
  }

  .cityScapeLandingPage .footerSection h6 {
    font-size: 1.2rem;
  }

  .cityScapeLandingPage .footerSection img {
    width: 20px;
    height: 20px;
    margin-left: 20px;
  }

  .cityScapeLandingPage .form_box .form-control {
    font-size: 1.2rem;
  }

  .cityScapeLandingPage .form_box button {
    font-size: 1.5rem;
  }
}

@media (max-width:500px) {
  .cityScapeLandingPage .bannerSection .bannerSection h6 {
    font-size: 2rem;
  }

  .cityScapeLandingPage .headerSection {
    padding: 0px;
  }

  .cityScapeLandingPage .secondSection .nisnatasiContent h6,
  .cityScapeLandingPage .thirdSection .bestErupe .bestEurpeContent h6 {
    font-size: 1rem;
  }

  .cityScapeLandingPage .thirdSection .bestErupe .bestEurpeContent img {
    width: 100px;
  }

  .cityScapeLandingPage .thirdSection .moderInfo .moderInfoContent h6 {
    font-size: 1.5rem;
  }

  .cityScapeLandingPage .citizenShipSection .bookPayment {
    display: block !important;
    text-align: center;
    padding-bottom: 20px;
  }

  .cityScapeLandingPage .form_box .form-control {
    font-size: 0.7rem;
  }

  .cityScapeLandingPage .citizenShipSection img.img-fluid.doller {
    width: 30%;
  }
}