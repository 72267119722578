.nutureDigitalSection {
    width: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
}

.nutureMainSection {
    width: 100%;
    position: relative;
    mix-blend-mode: multiply;
    transition: 1s;
}

.backgroundRed {
    background-color: rgba(0, 37, 50, 0.7);
    transform: translate(0%);
    transition: 1s;
}

.backgroundRedFalse {
    background-color: rgba(0, 37, 50, 0.7);
    transform: translate(100%);
    transition: 1s;
}

.frame {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
}

.wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    margin-top: 8px;
}

.boxContent {
    position: absolute;
    top: 0;
    color: #fff;
}

.box-container {
    display: flex;
    height: 100%;
}

.box {
    box-sizing: border-box;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    color: #fff;
    position: relative;
}


button.seeAllProjectButton a {
    text-decoration: none;
    color: #fff;
}

button.seeAllProjectButton {
    background: transparent;
    border: 1px solid;
    border-radius: 10px;
    width: 40%;
    height: 60%;
    padding: 10px;
    outline: none;
}

button.viewProjectButton {
    background-color: #f53f0c;
    border: 1px solid transparent;
    border-radius: 10px;
    width: 40%;
    height: 60%;
    margin-left: 29px;
    outline: none;
    padding: 10px;
}

.temaVideoBoxFalse .rh5v-Overlay_component {
    z-index: -3;
}

.nutureMainSection video {
    width: 100%;
}

.mixBlendFalse ul li {
    list-style: none;
}

.mixBlend ul li {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    list-style: none;
}

.oneMoreMix {
    width: 34vw;
    height: 34vw;
    margin: -17vw 0 0 -22.2vw;
    left: 38%;
    position: absolute;
    left: 32%;
    top: 50%;
    overflow: hidden;
    z-index: -2;
}

.oneMoreMix a {
    cursor: pointer;
}

.tema2dContent {
    position: absolute;
    left: 50%;
    width: 30%;
    top: 27%;
    justify-content: center;
    align-items: center;
    margin-top: -2%;
    text-align: left;
    z-index: 10;
    text-align: right;
}

.button-containerTrue {
    /* display: flex; */
    position: absolute;
    right: 0;
    z-index: 8;
    transform: scale(1);
    transition: 1s;
}

.button-containerFalse {
    position: absolute;
    right: 0;
    z-index: 8;
    transform: scale(0);
    transition: 1s;
}

@mixin buttonContainer($transform: scale(1)) {
    position: absolute;
    right: 0;
    z-index: 8;
    transition: 1s;
}

.button-containerTrue {
    @include buttonContainer()
}

.button-containerFalse {
    @include buttonContainer($transform: scale(0))
}

.button {
    color: #fff;
    width: 50px;
    text-align: center;
    padding: 10px 20px;
    margin: 10px;
    user-select: none;
    cursor: pointer;
}

/*LetterT and letterTFalse Css*/

.mixBlend .letterT li::before {
    content: "";
    position: absolute;
    background-color: #000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

$letterT-nth-child: 1 100% 12% 1s translate(0%),
    2 100% 12% 2s translate(0%) 12%,
    3 100% 12% 3s translate(0%) 24%,
    4 15% 66% 1s translate(0, 0%) 34% 32%,
    5 15% 66% 2s translate(0, 0%) 34% 44%,
    6 15% 66% 2.6s translate(0, 0%) 34% 56%;

@each $number ,
$width ,
$height ,
$transition ,
$transform,
$top ,
$left in $letterT-nth-child {
    .mixBlend .letterT li:nth-child(#{$number}) {
        width: $width;
        height: $height;
        transition: $transition ;
        transform: $transform;
        top: $top;
        left: $left;
    }
}

.mixBlend .letterTFalse li::before {
    content: "";
    position: absolute;
    background-color: #000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

$letterTFalse-nth-child: 1 100% 12% translate(-100%) 1s,
    2 100% 12% translate(-100%) 2s 12%,
    3 100% 12% translate(-100%) 3s 24%,
    4 15% 66% translate(0, 100%) 1s 34% 32%,
    5 15% 66% translate(0, 100%) 2s 34% 44%,
    6 15% 66% translate(0, 100%) 2.6s 34% 56%;

@each $number ,
$width ,
$height,
$transform ,
$transition ,
$top ,
$left in $letterTFalse-nth-child {
    .mixBlend .letterTFalse li:nth-child(#{$number}) {
        width: $width;
        height: $height;
        transform: $transform ;
        top: $top;
        left: $left;
        transition: $transition;
    }
}

.mixBlend .letterT li:nth-child(1)::before {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.mixBlend .letterT li:nth-child(3)::before {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.mixBlend .letterT li:nth-child(4)::before {
    border-bottom-left-radius: 10px;
}

.mixBlend .letterT li:nth-child(6)::before {
    border-bottom-right-radius: 10px;
}

/**/

/*LetterN and LetterNFalse*/

.mixBlend .letterN li::before {
    content: "";
    position: absolute;
    background-color: #000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

$letterN-nth-child: 1 35.1% 100% translate(0, 0%) 1s,
    2 51% 34.8% (rotate(53.7deg)skew(53.7deg)) 1s 32.5% 24.5% unset,
    3 35% 100 translate(0, 0%) 1s unset auto 0;

@each $number ,
$width ,
$height,
$transform ,
$transition ,
$top ,
$left ,
$right in $letterN-nth-child {
    .letterN li:nth-child(#{$number}) {
        width: $width;
        height: $height;
        transform: $transform ;
        transition: $transition;
        top: $top;
        left: $left;
        right: $right;
    }
}

.letterNFalse li::before {
    content: "";
    position: absolute;
    background-color: #000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

$letterNFalse-nth-child: 1 35.1% 100% translate(0, 100%) 1s,
    2 51% 34.8% translate(150%) 1s 32.5% 24.5% unset,
    3 35% 100 translate(0, 100%) 1s unset auto 0;

@each $number ,
$width ,
$height,
$transform ,
$transition ,
$top ,
$left ,
$right in $letterNFalse-nth-child {
    .letterNFalse li:nth-child(#{$number}) {
        width: $width;
        height: $height;
        transform: $transform ;
        transition: $transition;
        top: $top;
        left: $left;
        right: $right;
    }
}

@mixin mixblendBox($transform: translate(0%)) {
    background-color: #fff;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    mix-blend-mode: screen;
    transform: $transform;
    transition: 1s;
}

.mixBlend {
    @include mixblendBox();
}

.mixBlendFalse {
    @include mixblendBox($transform: translate(100%))
}

/*button*/
@mixin temalettercontent($transform: translate(0, 0), $fontsize: 15px) {
    font-size: $fontsize;
    transition: 1.5s;
    color: #fff;
    transform: $transform;
}

.tema2dContentDescriptionTrue p {
    @include temalettercontent;
}

.tema2dButtonTrue button {
    @include temalettercontent;
}

.tema2dContentDescriptionFalse p {
    @include temalettercontent($transform: translate(0, 120%))
}

.tema2dButtonFalse button {
    @include temalettercontent($transform: translate(0, 100%))
}

.temaContentBoxTrue h3 {
    @include temalettercontent($fontsize: 70px)
}

.temaContentBoxFalse h3 {
    @include temalettercontent($transform: translate(0, 100%), $fontsize: 70px)
}

@mixin tema2dbuttonbox() {
    display: flex;
    width: 100%;
    height: 8%;
    overflow: hidden;
    margin-top: 30px;
    justify-content: flex-end;
}

.tema2dButtonTrue {
    @include tema2dbuttonbox();
}

.tema2dButtonFalse {
    @include tema2dbuttonbox();
}

/**/


/*arrow*/
@mixin tema2dArrowSection($transition: transform 0.5s, $transform: scale(1), $top: 50%) {
    position: absolute;
    right: 0;
    transition: $transform;
    transform: $transform;
    top: $top;
}

.tema2dArrow {
    @include tema2dArrowSection();
}

.tema2dArrowFalse {
    @include tema2dArrowSection($transform: scale(0))
}

.tema2dClose {
    @include tema2dArrowSection($transition: transform 0.3s, $top: 46%)
}

.tema2dCloseFalse {
    @include tema2dArrowSection($transition: transform 0.3s, $transform: scale(0), $top: 46%)
}

/***/


/*description*/
@mixin temaContentBoxDescription($height: 22%) {
    overflow: hidden;
    width: 100%;
    height: $height;
}

.temaContentBoxTrue {
    @include temaContentBoxDescription();
}

.temaContentBoxFalse {
    @include temaContentBoxDescription();
}

.tema2dContentDescriptionTrue {
    @include temaContentBoxDescription($height: 12%);
}

.tema2dContentDescriptionFalse {
    @include temaContentBoxDescription($height: 12%);
}

/*tema2dArrowAfterBefore*/


/**/